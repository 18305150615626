// Libraries
import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

// Component
import PublicRoute from './routes/PublicRoute';
import PrivateRoutes from './routes/PrivateRoutes';
import ShareRoute from './routes/shareRoute';

import Signin from './pages/auth/Signin';
import Signup from './pages/auth/Signup';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import { AppLayout } from './components';
import MyAccount from './pages/my-account';
import PublicProfile from './pages/public-profile';

import HomePage from './pages/home';
import AboutPage from './pages/about';
import FitnessDirectoryPage from './pages/fitness-directory';
import ContactPage from './pages/contact';
import BlogPage from './pages/blog';
import BlogDetail from './pages/blog-detail';
import TermsAndCondition from './pages/termAndCondition';

// Styles
import './App.less';
import PrivacyAndPolicy from './pages/privacyAndPolicy';

function Entry() {
    return (
        <Routes>
            <Route element={<PublicRoute />}>
                <Route path="signin" element={<Signin />} />
                <Route path="signup" element={<Signup />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password/:id" element={<ResetPassword />} />
            </Route>
            <Route path="/" element={<ShareRoute />}>
                <Route path="/" element={<AppLayout />}>
                    <Route path="/" slug="Home" element={<HomePage />} />
                    <Route path="/:profileUrl" element={<PublicProfile />} />
                    <Route path="about-us" slug="About Us" element={<AboutPage />} />
                    <Route path="fitness-directory" slug="Fitness Directory" element={<FitnessDirectoryPage />} />
                    <Route path="contact-us" slug="Contact Us" element={<ContactPage />} />
                    {/* <Route path="blog" element={<BlogPage />} />
                    <Route path="blog-detail/:id" element={<BlogDetail />} /> */}
                    <Route path="privacy-policy" element={<PrivacyAndPolicy />} />
                    <Route path="terms-conditions" element={<TermsAndCondition />} />
                </Route>
            </Route>
            <Route path="/" element={<PrivateRoutes />}>
                <Route path="/" element={<AppLayout />}>
                    <Route path="/" element={<Navigate replace to="/myaccount" />} />
                    <Route path="myaccount" element={<MyAccount />} />
                    {/* <Route path="HomePage" element={<HomePage />} /> */}
                    {/* <Route path="about-us" slug="About Us" element={<AboutPage />} />
                    <Route path="fitness-directory" slug="Fitness Directory" element={<FitnessDirectoryPage />} />
                    <Route path="contact-us" slug="Contact Us" element={<ContactPage />} />
                    <Route path="blog" element={<BlogPage />} />
                    <Route path="blog-detail" element={<BlogDetail />} /> */}
                </Route>
                {/* <Route path="/" element={<AppLayout />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="myaccount" element={<MyAccount />} />
                    <Route path="about-us" slug="About Us" element={<AboutPage />} />
                    <Route path="fitness-directory" slug="Fitness Directory" element={<FitnessDirectoryPage />} />
                    <Route path="contact-us" slug="Contact Us" element={<ContactPage />} />
                    <Route path="blog" element={<BlogPage />} />
                    <Route path="blog-detail" element={<BlogDetail />} />
                </Route> */}
            </Route>
        </Routes>
    );
}

export default Entry;
