// Libraries
import React, { useEffect, useState } from 'react';
import { Form, Button, Upload, Typography, Input } from 'antd';

// Component

// Styles
import './style.css';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import domainurl from '../../../../constants/domainurl';
import useToken from '../../../../hooks/useToken';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import {OpenNotification} from '../../../../components/Notifications';

// Assets

const { Title } = Typography;

const AddPortfolio = (props) => {
    const dispatcher = useShareDispatch();
    const [beforefile, setBeforefile] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState();
    const [item, setItem] = useState(null)

    const [afterfile, setAfterfile] = useState(null)
    const [form] = Form.useForm();
    const { token } = useToken();
    const { userRolesInfo } = useMyAccountContext()

    const onUpdate = () => {
        props.onUpdatePortfolio(false)
    }
    console.log(userRolesInfo)

    const onFinish = (value) => {
        setIsSubmitting(true)

        let model = {
            _id: props.model._id ? props.model._id : -1,
            userRolesInfo: userRolesInfo.roleId,
            description: value.description

        }
        fetch(domainurl + "/users/addEditPortfolioData/", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` },
            body: JSON.stringify(model)

        })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status == "ok") {
                    setItem(res.data)
                    model._id = res.data._id
                    var Promisi1 = new Promise((resolve) => {
                        if (beforefile[0] && beforefile[0].originFileObj) {
                            model.beforefileName = beforefile[0].name.split(".")[0];
                            model.beforefileUploaded = beforefile[0].originFileObj
                            resolve();
                        }
                        else {
                            if (beforefile && beforefile.length > 0) {
                                model.beforefileName = "beforefile"
                                fetch(res.data.beforePath)
                                    .then(async response => {
                                        const contentType = response.headers.get('content-type')
                                        const blob = await response.blob()
                                        let file = new File([blob], "beforefile." + res.data.beforePath.split('.').pop(), { contentType })
                                        model.beforefileUploaded = file
                                        resolve();
                                    })
                            } else {
                                resolve()
                            }
                        }
                    })
                    var Promisi2 = new Promise((resolve) => {
                        if (afterfile[0] && afterfile[0].originFileObj) {
                            model.afterfileName = afterfile[0].name.split(".")[0];
                            model.afterfileUpload = afterfile[0].originFileObj
                            resolve();
                        }
                        else {
                            if (afterfile && afterfile.length > 0) {
                                model.afterfileName = "afterPath"
                                fetch(res.data.afterPath)
                                    .then(async response => {
                                        const contentType = response.headers.get('content-type')
                                        const blob = await response.blob()
                                        let file = new File([blob], "afterPath." + res.data.afterPath.split('.').pop(), { contentType })
                                        model.afterfileUpload = file
                                        resolve();
                                    })
                            }
                            else {
                                resolve()
                            }
                        }
                    })

                    Promise.all([Promisi1, Promisi2]).then(item => {
                        uploadImage(model)
                    })
                }

            }).catch(err => {
                setIsSubmitting(false)
            })
    };

    useEffect(() => {
        if (props.model._id) {
            form.setFieldsValue({
                description: `${props.model.description}`,
                BeforeImage: 'BeforeImage',
                AfterImage: 'AfterImage',
            });
            setAfterfile([{
                url: props.model.afterPath,
                name: 'afterfile'
            }])
            setBeforefile([{
                url: props.model.beforePath,
                name: 'beforefile'
            }])
        }
        else {
            form.resetFields();
        }

    }, [])

    const uploadImage = (item) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.Accept = 'application/json';
        var formdata = new FormData();
        if (item.beforefileUploaded) formdata.append("image", item.beforefileUploaded)
        if (item.afterfileUpload) formdata.append("image", item.afterfileUpload)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow',

        };
        console.log(item.beforefileName)
        console.log(item.afterfileName)

        fetch(domainurl + "/users/addEditPortfolioImage/" + userRolesInfo.roleId + "/" + item._id + "/" + item.beforefileName + "/" + item.afterfileName, requestOptions)
            .then(res => res.json())
            .then(res => {
                if (res.status == "ok") {
                    OpenNotification("", item.message)
                    props.onUpdatePortfolio(false)
                    let passingObje = {
                        userRoledId: userRolesInfo.roleId,
                        token: token,
                        tab: 'portfolio'
                    }
                    dispatcher(actionsApi.getUserTabDetails(passingObje))
                }
                setIsSubmitting(false)

            }).catch(err => {
                setIsSubmitting(false)

            })
    }


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const handleBeforeChangeImage = (file) => {
        console.log('BeforefileList', file);
        form.setFieldValue({
            BeforeImage: '',
        })
        setBeforefile(file.fileList)
    };

    const handleAfterChangeImage = (file) => {
        form.setFieldValue({
            AfterImage: '',
        })
        console.log('AfterfileList', file.fileList);
        setAfterfile(file.fileList)

    };

    return (
        <div className='fs-my-account fs-portfolio'>
            <Form
                name="editAboutModel"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >
                <div className='fs-about-head'>
                    <Title level={2}>Add Success stories</Title>
                </div>
                <div className='fs-portfolio-card fs-add-portfolio fs-mt-30'>
                    <ul className='fs-about-list'>
                        <li>
                            <div className='fs-about-title'>Select Before Image</div>
                            <div className='fs-about-end'>
                                <Form.Item
                                    name="BeforeImage"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please upload  Before Image',
                                        },
                                    ]}
                                >
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        fileList={beforefile}
                                        maxCount={1}
                                        onChange={handleBeforeChangeImage}
                                        beforeUpload={(file) => {
                                            return false
                                        }
                                        }
                                    >
                                        <Button className='ant-btn ant-btn-primary'>Upload Image</Button>
                                    </Upload>
                                </Form.Item>
                            </div>
                        </li>
                        <li>
                            <div className='fs-about-title'>Select After Image</div>
                            <div className='fs-about-end'>
                                <Form.Item
                                    name="AfterImage"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please upload  After Image',
                                        },
                                    ]}
                                >
                                    <Upload
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture"
                                        maxCount={1}
                                        fileList={afterfile}
                                        onChange={handleAfterChangeImage}
                                        beforeUpload={(file) => {
                                            return false
                                        }
                                        }
                                    >
                                        <Button className='ant-btn ant-btn-primary'>Upload Image</Button>
                                    </Upload>
                                </Form.Item>

                            </div>
                        </li>

                        <li>
                            <div className='fs-about-title'>Description</div>
                            <div className='fs-about-end'>
                                <Form.Item
                                    name="description"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input Description',
                                        },
                                    ]}
                                >
                                    <Input.TextArea placeholder='Please input Description' />
                                </Form.Item>
                            </div>
                        </li>
                    </ul>
                    <div className='fs-btn-group fs-mt-20'>
                        <Button type="primary" htmlType="submit" loading={isSubmitting} className='fs-mr-15 fs-black'>Save</Button>
                        <Button type="primary" onClick={onUpdate}  >Cancel</Button>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default AddPortfolio;