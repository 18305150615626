import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import interceptor from "../../../shared/interceptor";

export const getUserTabDetails = createAsyncThunk(
    "user/detail",

    async (object, { dispatch, getState }) => {
        dispatch(gettingResponse())
        if (object.clear) {
            dispatch(gettingUserOnChangeTab())
        }
        interceptor.axiosGet("get", "/users/" + (object.readOnly ? 'detailView/' : 'detail/') + object.userRoledId + '/false/' + object.tab, object.token, {

        }).then(res => {
            // saveToken(res)
            if (res.status == 200) {
                dispatch(gettingResponseSuccess(res.data.result))
                if (object.tab == 'about') {
                    dispatch(gettingUserDetail(res.data.result))
                }
                if (object.tab == 'video') {
                    dispatch(gettingUserVideos(res.data.result))
                }
                if (object.tab == 'gallery') {
                    dispatch(gettingUserGallery(res.data.result))
                }
                if (object.tab == 'ads') {
                    dispatch(gettingUserAds(res.data.result))
                }
                if (object.tab == 'portfolio') {
                    dispatch(gettingUserPortfolio(res.data.result))
                }



            } else {
                dispatch(gettingResponseSuccess({}))
            }


        }).catch(error => {
            dispatch(gettingResponseError(error))
        })

    },
);

export const setRoleDetailsClear = createAsyncThunk(
    "user/Clear",

    async (object, { dispatch, getState }) => {
        dispatch(setResponseClear(null))

    },
);


const userDetailSlice = createSlice({
    name: "user_detail",
    initialState: {
        status: "idle",
        userTabData: null,
        userVideos: null,
        userGallery: null,
        fcList: null,
        userPortfolio: null,
        userAds: null,
        isLoadingUserDetail: false,
        error: null,
        isUserDetailError: false,
    },
    reducers: {
        gettingResponseError: (state, action) => {
            state.status = "error";
            state.error = action.payload;
            state.isLoadingUserDetail = false;
            state.isUserDetailError = true;
        },
        gettingUserDetail: (state, action) => {
            state.status = "success";
            state.userTabData = action.payload
            state.isLoadingUserDetail = false;
            state.isUserDetailError = false;
        },

        gettingUserVideos: (state, action) => {
            state.status = "success";
            state.userVideos = action.payload
            state.isLoadingUserDetail = false;
            state.isUserDetailError = false;
        },

        gettingUserGallery: (state, action) => {
            state.status = "success";
            state.userGallery = action.payload
            state.isLoadingUserDetail = false;
            state.isUserDetailError = false;
        },
        gettingUserPortfolio: (state, action) => {
            state.status = "success";
            state.userPortfolio = action.payload
            state.isLoadingUserDetail = false;
            state.isUserDetailError = false;
        },

        gettingUserAds: (state, action) => {
            state.status = "success";
            state.userAds = action.payload
            state.isLoadingUserDetail = false;
            state.isUserDetailError = false;
        },

        gettingFCList: (state, action) => {
            state.status = "success";
            state.fcList = action.payload
            state.isLoadingUserDetail = false;
            state.isUserDetailError = false;
        },

        gettingUserOnChangeTab: (state) => {
            state.userTabData = null;
            state.userVideos = null;
            state.userGallery = null;
            state.userPortfolio = null;
            state.userAds = null;
        },

        gettingResponseSuccess: (state, action) => {
            state.status = "success";
            state.isLoadingUserDetail = false;
            state.isUserDetailError = false;
        },
        gettingResponse: (state, action) => {
            state.status = "loading";
            state.isLoadingUserDetail = true;
            // state.userTabData = null;
            state.isUserDetailError = false;
        },

        setResponseClear: (state, action) => {
            state.status = "idle";
            state.userTabData = null;
            state.userVideos = null;
            state.userGallery = null;
            state.fcList = null;
            state.serPortfolio = null;
            state.userAds = null;
            state.isLoadingUserDetail = false;
            state.error = null;
            state.isUserDetailError = false;

        },


    },

});


// Action creators are generated for each case reducer function
export const { gettingResponse, setResponseClear, gettingUserDetail, gettingUserVideos, gettingFCList, gettingUserOnChangeTab, gettingUserGallery, gettingUserPortfolio, gettingUserAds, gettingResponseError, gettingResponseSuccess } =
    userDetailSlice.actions;

export default userDetailSlice.reducer;
