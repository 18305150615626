// Libraries
import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Select, Button, Image, Table } from 'antd';

// Component

// Styles
import './style.css';

// Assets
import image from '../../../../assets/images/hero.jpg';
import { BodyTypeArray, CompensationArray, experienceArray, modelingInterestArray, ModelingInterestsArray, skinTone, SkinToneArray } from '../../../../data/fitnessCenters';
import domainurl from '../../../../constants/domainurl';
import { Link } from 'react-router-dom';
import useToken from '../../../../hooks/useToken';

const { Option } = Select;

const columns = [
    {
        title: 'Fitness Model',
        key: 'imagePath',
        render: (value) => <Image preview={false} src={value && value.imagePath ? value.imagePath : image} alt="" />
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => <Link to={`/${record.profileUrl}`}>{text}</Link>,

    },
    {
        title: 'Skin Color',
        dataIndex: 'skinTone',
        key: 'skinTone',
    },
    {
        title: 'Ethnicity',
        dataIndex: 'ethnicity',
        key: 'ethnicity',
    },
    {
        title: 'Modeling Interest',
        dataIndex: 'modelingInterests',
        key: 'modelingInterests',
    },
    // {
    //     title: 'Rating',
    //     dataIndex: 'rating',
    //     key: 'rating',
    // },
];


const FitnessModels = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const { userLoginObject } = useToken();
    const [filterList, setFilterlist] = useState([]);

    const [form] = Form.useForm();

    useEffect(() => {
        getFPList(form.getFieldValue());
    }, [""]);



    const getFPList = (values) => {
        // if (userLoginObject && userLoginObject.data) {
        //     values.loginUserId = (userLoginObject || {}).data._id;
        // }
        setIsLoading(true)
        fetch(domainurl + '/users/getFMUserList', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    setData(res.result)
                    setFilterlist(res.result)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    const onFinish = (values) => {
        console.log('Success:', values);
        let filter = data
        if (values.name && values.name.toLowerCase() != "") {
            filter = filter.filter(x => x.name.toLowerCase().includes(values.name.toLowerCase()));
        }
        if (values.modelingInterests && values.modelingInterests.toLowerCase() != "") {
            filter = filter.filter(x => x.modelingInterests.toLowerCase().includes(values.modelingInterests.toLowerCase()));
        }
        if (values.gender && values.gender.toLowerCase() != "") {
            values.gender = values.gender == "male" ? "1" : "2"
            filter = filter.filter(x => x.gender.toLowerCase().includes(values.gender.toLowerCase()));
        }

        if (values.location && values.location.toLowerCase() != "") {
            filter = filter.filter(x => (x.country.toLowerCase() + " " + x.city.toLowerCase() + ' ' + x.state.toLowerCase()).includes(values.location.toLowerCase()));
        }

        if (values.skinTone && values.skinTone.toLowerCase() != "") {
            filter = filter.filter(x => x.skinTone.toLowerCase().includes(values.skinTone.toLowerCase()));
        }
        if (values.compensation && values.compensation.toLowerCase() != "") {
            filter = filter.filter(x => x.compensation.toLowerCase().includes(values.compensation.toLowerCase()));
        }
        if (values.bodyType && values.bodyType.toLowerCase() != "") {
            filter = filter.filter(x => x.bodyType.toLowerCase().includes(values.bodyType.toLowerCase()));
        }
        setFilterlist(filter)

        // getFPList(values)
    };


    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='fs-fitness'>
            <div className='fs-container'>
                <Form
                    name="fitnessProForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Row gutter={25}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="compensation"
                            >
                                {/* <Input placeholder='Name' /> */}
                                <Select
                                    placeholder="Compensation"
                                    showArrow
                                    allowClear
                                >
                                    {CompensationArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="modelingInterests"
                            >
                                <Select
                                    placeholder="Interest"
                                    allowClear
                                    showArrow
                                >
                                    {ModelingInterestsArray.map((item) => (
                                        <Select.Option key={item.name} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="location"
                            >
                                <Input placeholder='Location' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="skinTone"
                            >
                                <Select
                                    placeholder="Skin Color"
                                    showArrow
                                    allowClear
                                >
                                    {SkinToneArray.map((item) => (
                                        <Select.Option key={item.key} value={item.name}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="gender"
                            >
                                <Select
                                    placeholder="Gender"
                                    allowClear
                                    showArrow
                                >
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                    <Option value="other">Other</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="bodyType"
                            >
                                <Select
                                    placeholder="Body Type"
                                    allowClear
                                    showArrow
                                >
                                    {BodyTypeArray.map((item, key) => (
                                        <Select.Option key={key} value={item.key}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='fs-right'>
                        {/* <Button type="default" className='fs-mr-15' onClick={() => { form.resetFields(); }}>Reset</Button> */}
                        <Button type="primary" htmlType="submit">Search Fitness Models</Button>
                    </div>
                </Form>
                <Table
                    columns={columns}
                    dataSource={filterList}
                    loading={isLoading}
                    scroll={{
                        x: 800,
                    }}
                    className="fs-pros fs-center" />
            </div>
        </div>
    );
}

export default FitnessModels;