// Libraries
import React, { useState, useEffect } from 'react';
import { Select, Typography, Button, Form, Input, Radio, Rate, Upload } from 'antd';
import { Link } from 'react-router-dom';
import { Edit3 } from 'react-feather';

// Component

// Styles
import './style.css';
import { useForm } from 'rc-field-form';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import BusinessType from './BusinessType';
import useToken from '../../../../hooks/useToken';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import interceptor from '../../../../shared/interceptor';
import FitnessModal from './FitnessModal';
import FitnessProfessional from './FitnessProfessional';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import moment from 'moment';
import domainurl from '../../../../constants/domainurl';
import { OpenNotification, OpenSuccessNotification } from '../../../../components/Notifications';

// Assets

const { Option } = Select;
const { Title } = Typography;

const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];

const EditAbout = (props) => {
    const { userTabData } = useShareSelector(state => state.userTabDetail);

    const [isLoader, setIsLoader] = useState(false)
    const { userLoginObject, token } = useToken();
    const [selectedItems, setSelectedItems] = useState([]);
    const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
    const { fitnessCenterType, userRolesInfo, setTabType, otherCertification } = useMyAccountContext()
    const dispatcher = useShareDispatch();
    // "FP"="fitness Professional"
    // "FC"="Fitness Center"
    // "FM"="Fitness Modal"

    const [form] = Form.useForm();
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        setValue(e.target.value);
    };

    useEffect(() => {

        // business center value
        onLoadData(fitnessCenterType)

    }, [])

    const onFinish = (values) => {
        console.log('Success:updated', values);
        submitAbout(values)
    };

    const onUpdate = () => {
        props.onUpdateAbout(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        OpenNotification("topRight", errorInfo.errorFields[0].errors)
    };
    const onLoadData = (centerType) => {
        switch (centerType) {
            case "FC":
                let fitnessCenterAbout = userTabData && userTabData.fitnessCenterAbout;
                if (fitnessCenterAbout && Object.keys(fitnessCenterAbout).length != 0) {
                    let time = [moment(fitnessCenterAbout.timeRange[0]), moment(fitnessCenterAbout.timeRange[1])]
                    console.log(time)
                    let weekday = fitnessCenterAbout.timeRange ? JSON.parse(fitnessCenterAbout.timeRange[0]) : ''
                    console.log(weekday)
                    form.setFieldsValue({
                        profileurl: fitnessCenterAbout.profileUrl ? fitnessCenterAbout.profileUrl : "",
                        OpenSince: fitnessCenterAbout.openSince.length > 2 ? moment(fitnessCenterAbout.openSince) : '',
                        accomplishments: fitnessCenterAbout.accomplishments ? fitnessCenterAbout.accomplishments : "",
                        inHomeTraining: fitnessCenterAbout.inHomeTraining ? fitnessCenterAbout.inHomeTraining : "",
                        businessName: fitnessCenterAbout.businessName ? fitnessCenterAbout.businessName : "",
                        fitnessCenterType: fitnessCenterAbout.fitnessCenterType ? fitnessCenterAbout.fitnessCenterType : "",
                        Monday: weekday[0].value ? [moment(weekday[0].value[0]), moment(weekday[0].value[1])] : '',
                        Tuesday: weekday[1].value ? [moment(weekday[1].value[0]), moment(weekday[1].value[1])] : '',
                        Wednesday: weekday[2].value ? [moment(weekday[2].value[0]), moment(weekday[2].value[1])] : '',
                        Thursday: weekday[3].value ? [moment(weekday[3].value[0]), moment(weekday[3].value[1])] : '',
                        Friday: weekday[4].value ? [moment(weekday[4].value[0]), moment(weekday[4].value[1])] : '',
                        Saturday: weekday[5].value ? [moment(weekday[5].value[0]), moment(weekday[5].value[1])] : '',
                        Sunday: weekday[6].value ? [moment(weekday[6].value[0]), moment(weekday[6].value[1])] : '',
                        language: fitnessCenterAbout.languages ? fitnessCenterAbout.languages : "",
                        membershipplans: fitnessCenterAbout.membershipPlans ? fitnessCenterAbout.membershipPlans : "",
                        ourFitnessPros: fitnessCenterAbout.ourFitnessPros ? fitnessCenterAbout.ourFitnessPros : "",
                        sepecialties: fitnessCenterAbout.specialties ? fitnessCenterAbout.specialties : "",
                        trainingNote: fitnessCenterAbout.OurStoryAboutUs ? fitnessCenterAbout.OurStoryAboutUs : "",

                    });
                }

                break;
            case "FM":
                let fitnessModalAbout = userTabData && userTabData.fitnessModalAbout;
                if (fitnessModalAbout && Object.keys(fitnessModalAbout).length != 0) {
                    form.setFieldsValue({
                        profileUrl: fitnessModalAbout.profileUrl,
                        name: fitnessModalAbout.name,
                        gender: parseInt(fitnessModalAbout.gender),
                        age: fitnessModalAbout.ageYears,
                        height: fitnessModalAbout.height,
                        selectHeight: fitnessModalAbout.heightMeasurement,
                        weight: fitnessModalAbout.weight,
                        selectWeight: fitnessModalAbout.weightMeasurement,
                        bodyType: fitnessModalAbout.bodyType,
                        ethnicity: fitnessModalAbout.ethnicity,
                        skinTone: fitnessModalAbout.skinTone,
                        eyeColor: fitnessModalAbout.eyeColor,
                        hairLength: fitnessModalAbout.hairLength,
                        tattoos: fitnessModalAbout.tattoos,
                        pricing: fitnessModalAbout.pricing,
                        experience: fitnessModalAbout.experience,
                        languages: fitnessModalAbout.languages,
                        workingWithMedia: fitnessModalAbout.workingWithMedia,
                        ModelingInterests: fitnessModalAbout.modelingInterests,
                        Compensation: fitnessModalAbout.compensation,
                        notesAboutComensation: fitnessModalAbout.notesAboutCompensation,
                        activities: fitnessModalAbout.activities,
                        StoryAboutme: fitnessModalAbout.myStoryOrAboutMe,
                        sponsors: fitnessModalAbout.sponsors


                    });
                } else {
                    // form.setFieldsValue({
                    //     profileUrl: fitnessModalAbout.profileUrl,
                    //     name: fitnessModalAbout.profileUrl,
                    //     gender: fitnessModalAbout.gender,
                    //     age: fitnessModalAbout.ageYears,
                    //     height: fitnessModalAbout.height,
                    //     selectHeight: fitnessModalAbout.heightMeasurement,
                    //     weight: fitnessModalAbout.weight,
                    //     selectWeight: fitnessModalAbout.weightMeasurement,
                    //     bodyType: fitnessModalAbout.bodyType,
                    //     ethnicity: fitnessModalAbout.ethnicity,
                    //     skinTone: fitnessModalAbout.skinTone,
                    //     eyeColor: fitnessModalAbout.eyeColor,
                    //     hairLength: fitnessModalAbout.hairLength,
                    //     tattoos: fitnessModalAbout.tattoos,
                    //     pricing: fitnessModalAbout.pricing,
                    //     experience: fitnessModalAbout.experience,
                    //     languages: fitnessModalAbout.languages,
                    //     workingWithMedia: fitnessModalAbout.workingWithMedia,
                    //     ModelingInterests: fitnessModalAbout.modelingInterests,
                    //     Compensation: fitnessModalAbout.compensation,
                    //     notesAboutComensation: fitnessModalAbout.notesAboutCompensation,
                    //     activities: fitnessModalAbout.activities,
                    //     StoryAboutme: fitnessModalAbout.myStoryOrAboutMe,

                    // });
                }


                break;
            case "FP":
                let fitnessProfessionalAbout = userTabData && userTabData.fitnessProfessionalAbout;
                if (fitnessProfessionalAbout && Object.keys(fitnessProfessionalAbout).length != 0) {
                    var model = {
                        // business: {
                        availabilityin_home_training: fitnessProfessionalAbout.business.availabilityForInHomeTaining ? fitnessProfessionalAbout.business.availabilityForInHomeTaining : "",
                        Availabilityforonline_training: fitnessProfessionalAbout.business.availabilityForOnlineliveTaining ? fitnessProfessionalAbout.business.availabilityForOnlineliveTaining : "",
                        businessName: fitnessProfessionalAbout.business.businessName ? fitnessProfessionalAbout.business.businessName : "",
                        businessId: fitnessProfessionalAbout.business._id ? fitnessProfessionalAbout.business._id : -1,

                        clientPreference: fitnessProfessionalAbout.business.clientPreference ? fitnessProfessionalAbout.business.clientPreference : "",
                        noteaboutTrainingRates: fitnessProfessionalAbout.business.noteAboutTainingRates ? fitnessProfessionalAbout.business.noteAboutTainingRates : "",
                        trainingRates: fitnessProfessionalAbout.business.trainingRates ? fitnessProfessionalAbout.business.trainingRates : "",
                        availabilityFoCorporateFitness: fitnessProfessionalAbout.business.availabilityFoCorporateFitness ? fitnessProfessionalAbout.business.availabilityFoCorporateFitness : "",
                        addressLine1: fitnessProfessionalAbout.business && fitnessProfessionalAbout.business.addressLine1 ? fitnessProfessionalAbout.business.addressLine1 : "",
                        addressLine2: fitnessProfessionalAbout.business && fitnessProfessionalAbout.business.addressLine2 ? fitnessProfessionalAbout.business.addressLine2 : "",
                        addressLine3: fitnessProfessionalAbout.business && fitnessProfessionalAbout.business.addressLine3 ? fitnessProfessionalAbout.business.addressLine3 : "",

                        // },
                        // personal: {
                        personalId: fitnessProfessionalAbout.personal._id ? fitnessProfessionalAbout.personal._id : -1,

                        activities: fitnessProfessionalAbout.personal.activities ? fitnessProfessionalAbout.personal.activities : "",
                        age: fitnessProfessionalAbout.personal.ageYears ? fitnessProfessionalAbout.personal.ageYears : "",
                        BodyType: fitnessProfessionalAbout.personal.bodyType ? fitnessProfessionalAbout.personal.bodyType : "",
                        DietType: fitnessProfessionalAbout.personal.dietType ? fitnessProfessionalAbout.personal.dietType : "",
                        gender: fitnessProfessionalAbout.personal.gender ? parseInt(fitnessProfessionalAbout.personal.gender) : "",
                        height: fitnessProfessionalAbout.personal.height ? fitnessProfessionalAbout.personal.height : "",
                        selectHeight: fitnessProfessionalAbout.personal.heightMeasurement ? fitnessProfessionalAbout.personal.heightMeasurement : "",
                        StoryAboutme: fitnessProfessionalAbout.personal.myStoryOrAboutMe ? fitnessProfessionalAbout.personal.myStoryOrAboutMe : "",
                        persionalname: fitnessProfessionalAbout.personal.name ? fitnessProfessionalAbout.personal.name : "",
                        profileUrl: fitnessProfessionalAbout.personal.profileUrl ? fitnessProfessionalAbout.personal.profileUrl : "",
                        weight: fitnessProfessionalAbout.personal.weight ? fitnessProfessionalAbout.personal.weight : "",
                        selectWeight: fitnessProfessionalAbout.personal.weightMeasurement ? fitnessProfessionalAbout.personal.weightMeasurement : "",
                        fitnessCenters: fitnessProfessionalAbout.personal.fitnessCenters ? fitnessProfessionalAbout.personal.fitnessCenters : "",

                        // },
                        // qualification: {
                        qualificationId: fitnessProfessionalAbout.qualification._id ? fitnessProfessionalAbout.qualification._id : -1,
                        certificateArray: fitnessProfessionalAbout.qualification.certificationsOrTrainings ? fitnessProfessionalAbout.qualification.certificationsOrTrainings : "",
                        certifications: fitnessProfessionalAbout.qualification.certifications ? fitnessProfessionalAbout.qualification.certifications : "",
                        degreesDiplomas: fitnessProfessionalAbout.qualification.degrees ? fitnessProfessionalAbout.qualification.degrees : "",
                        experience: fitnessProfessionalAbout.qualification.experience ? fitnessProfessionalAbout.qualification.experience : "",
                        languages: fitnessProfessionalAbout.qualification.languages ? fitnessProfessionalAbout.qualification.languages : "",
                        professions: fitnessProfessionalAbout.qualification.professions ? fitnessProfessionalAbout.qualification.professions : "",
                        specialties: fitnessProfessionalAbout.qualification.specialties ? fitnessProfessionalAbout.qualification.specialties : "",
                        trainingMethods: fitnessProfessionalAbout.qualification.trainingMethodsAndStyles ? fitnessProfessionalAbout.qualification.trainingMethodsAndStyles : "",
                        sponsors: fitnessProfessionalAbout.sponsors
                        //}
                    }
                    model.gender = parseInt(model.gender)
                    form.setFieldsValue(model);
                }
                break;


        }
    }

    const submitAbout = (values) => {
        setIsLoader(true)
        switch (fitnessCenterType) {
            case "FC":
                fitnessCenter(values);
                break;
            case "FM":
                fitnessModal(values);
                break;
            case "FP":
                debugger
                if (values.certificateArray.includes("Other")) {
                    values.certifications = ""
                    values.certifications = otherCertification
                }
                fintessProfessional(values)
                break;

        }



    }
    const fintessProfessional = (values) => {
        let id = userTabData.fitnessProfessionalAbout && Object.keys(userTabData.fitnessProfessionalAbout).length != 0 ? userTabData.fitnessProfessionalAbout._id : "-1"

        let object = {
            "info": {
                "_id": id,
                "business": {
                    "_id": values.businessId ?values.businessId : '-1',
                    "businessName": values.businessName,
                    "clientPreference": values.clientPreference,
                    "availabilityForInHomeTaining": values.availabilityin_home_training,
                    "availabilityForOnlineliveTaining": values.Availabilityforonline_training,
                    "availabilityFoCorporateFitness": values.availabilityFoCorporateFitness,
                    "addressLine1": values.addressLine1,
                    "addressLine2": values.addressLine2,
                    "addressLine3": values.addressLine3,
                    "trainingRates": values.trainingRates,
                    "noteAboutTainingRates": values.noteaboutTrainingRates,
                    "createdAt": "string",
                    "updatedAt": "string"
                },
                "qualification": {
                    "_id": values.qualificationId ?values.qualificationId : '-1',
                    "degrees": values.degreesDiplomas,
                    "professions": values.professions,
                    "experience": values.experience,
                    "certificationsOrTrainings": values.certificateArray,
                    "certifications": values.certificateArray && values.certificateArray.includes("Other") ? values.certifications : '',
                    // "certificationsOrTrainings": [
                    //     "string"
                    // ],
                    "specialties": values.specialties,
                    // "specialties": [
                    //     "string"
                    // ],
                    "languages": values.languages,
                    // "languages": [
                    //     "string"
                    // ],
                    "trainingMethodsAndStyles": values.trainingMethods,
                    "createdAt": "string",
                    "updatedAt": "string"
                },
                "personal": {
                    "_id": values.personalId ?values.personalId : '-1',
                    "name": values.persionalname,
                    "profileUrl": values.profileUrl.toLowerCase(),
                    "gender": values.gender,
                    "ageYears": parseInt(values.age),
                    "height": values.height,
                    "heightMeasurement": values.selectHeight,
                    "weight": parseInt(values.weight),
                    "weightMeasurement": values.selectWeight,
                    "dietType": values.DietType,
                    "bodyType": values.BodyType,
                    "activities": values.activities,
                    // "activities": [
                    //     "string"
                    // ],
                    "fitnessCenters": values.fitnessCenters,
                    "myStoryOrAboutMe": values.StoryAboutme,
                    "createdAt": "string",
                    "updatedAt": "string"
                },

            },
            "productReviews": [
                {
                    "_id": "-1",
                    "userId": "string",
                    "product": "string",
                    "reviews": "string",
                    "star": 0
                }
            ]
        }

        let sponsers = values.sponsors && values.sponsors.fileList && values.sponsors.fileList.length > 0 && values.sponsors.fileList && values.sponsors.fileList.length > 0
            ? values.sponsors.fileList
            : values.sponsors && values.sponsors.length > 0 && (values.sponsors.fileList && values.sponsors.fileList.length == 0 || values.sponsors[0].fpAboutId)
                ? values.sponsors.file && values.sponsors.file.status == 'removed'
                    ? null
                    : values.sponsors
                : null

        // let sponsers = values.sponsors && values.sponsors.fileList && values.sponsors.fileList.length > 0 ? values.sponsors.fileList :
        //     values.sponsors && (values.sponsors.fileList && values.sponsors.fileList.length == 0 || values.sponsors[0].fpAboutId) ?
        //         values.sponsors.file && values.sponsors.file.status == 'removed' ? null : values.sponsors : null
        if (sponsers && sponsers[0].fpAboutId) {
            let obj = []
            sponsers.forEach(item => {
                obj.push({
                    uid: item._id,
                    url: item.path,
                })
            });
            sponsers = obj
        }
        let sponsersArray = []
        var Promisi1 = new Promise((resolve) => {
            if (sponsers) {
                sponsers.forEach((item, index) => {
                    if (item.url) {
                        fetch(item.url)
                            .then(async response => {
                                const contentType = response.headers.get('content-type')
                                const blob = await response.blob()
                                let file = new File([blob], "image." + item.url.split('.').pop(), { contentType })
                                sponsersArray.push(file)
                                if (sponsersArray.length == sponsers.length) {
                                    resolve();
                                }
                            })

                    } else {
                        sponsersArray.push(item.originFileObj)
                        if (sponsersArray.length == sponsers.length) {
                            resolve();
                        }
                    }
                })
            } else {
                resolve();
            }
        })




        Promise.all([Promisi1]).then(item => {
            console.log(sponsersArray)
            callingApiUpload("/users/fitnessProfessionalAbout/" + id, object, sponsersArray)
        })

    }
    const fitnessCenter = (values) => {
        let id = userTabData.fitnessCenterAbout && Object.keys(userTabData.fitnessCenterAbout).length != 0 ? userTabData.fitnessCenterAbout._id : "-1"
        let fitnessCenter = {
            "info": {
                "_id": id,
                "businessName": values.businessName,
                "profileUrl": values.profileurl.toLowerCase(),
                "openSince": moment(values.OpenSince).format("YYYY"),
                "fitnessCenterType": values.fitnessCenterType,
                "inHomeTraining": values.inHomeTraining,
                "languages": values.language,
                "specialties": values.sepecialties,
                "timeRange": JSON.stringify([
                    {
                        day: 'Monday',
                        value: values.Monday,
                    },
                    {
                        day: 'Tuesday',
                        value: values.Tuesday,
                    },
                    {
                        day: 'Wednesday',
                        value: values.Wednesday,
                    },
                    {
                        day: 'Thursday',
                        value: values.Thursday,
                    },
                    {
                        day: 'Friday',
                        value: values.Friday,
                    },
                    {
                        day: 'Saturday',
                        value: values.Saturday,
                    },
                    {
                        day: 'Sunday',
                        value: values.Sunday,
                    },
                ]),

                "membershipPlans": values.membershipplans ? values.membershipplans : '',
                "accomplishments": values.accomplishments,
                "ourFitnessPros": values.ourFitnessPros,

                "OurStoryAboutUs": values.trainingNote,
                "createdAt": "string",
                "updatedAt": "string"
            },
            "productReviews": [
                {
                    "_id": id,
                    "userId": "string",
                    "product": "string",
                    "reviews": "string",
                    "star": 0
                }
            ]
        }
        console.log(fitnessCenter)

        callingApi("/users/fitnessCenterAbout", fitnessCenter)
    }
    const fitnessModal = (formValue) => {
        let id = userTabData.fitnessModalAbout && Object.keys(userTabData.fitnessModalAbout).length != 0 ? userTabData.fitnessModalAbout._id : "-1"
        let object = {
            "info": {
                "_id": id,
                "profileUrl": formValue.profileUrl.toLowerCase(),
                "gender": formValue.gender,// string
                "name": formValue.name,
                "ageYears": formValue.age,// integer
                "height": formValue.height,// integer
                "heightMeasurement": formValue.selectHeight,
                "weight": formValue.weight,//integer
                "weightMeasurement": formValue.selectWeight,
                "bodyType": formValue.bodyType,
                "ethnicity": formValue.ethnicity,
                "skinTone": formValue.skinTone,
                "eyeColor": formValue.eyeColor,
                "hairLength": formValue.hairLength,
                "tattoos": formValue.tattoos,
                "pricing": formValue.pricing,
                "experience": formValue.experience,
                "languages": formValue.languages,
                "workingWithMedia": formValue.workingWithMedia,
                "modelingInterests": formValue.ModelingInterests,//array
                "compensation": formValue.Compensation,
                "notesAboutCompensation": formValue.notesAboutComensation,
                "activities": formValue.activities,
                "myStoryOrAboutMe": formValue.StoryAboutme
            },
            "productReviews": [
                {
                    "_id": id,
                    "userId": "string",
                    "product": "string",
                    "reviews": "string",
                    "star": 0
                }
            ]
        }
        let sponsers = formValue.sponsors && formValue.sponsors.fileList && formValue.sponsors.fileList.length > 0 && formValue.sponsors.fileList && formValue.sponsors.fileList.length > 0
            ? formValue.sponsors.fileList
            : formValue.sponsors && formValue.sponsors.length > 0 && (formValue.sponsors.fileList && formValue.sponsors.fileList.length == 0 || formValue.sponsors[0].fmAboutId)
                ? formValue.sponsors.file && formValue.sponsors.file.status == 'removed'
                    ? null
                    : formValue.sponsors
                : null
        if (sponsers && sponsers[0].fmAboutId) {
            let obj = []
            sponsers.forEach(item => {
                obj.push({
                    uid: item._id,
                    url: item.path,
                })
            });
            sponsers = obj
        }
        debugger
        let sponsersArray = []
        var Promisi1 = new Promise((resolve) => {
            if (sponsers) {
                sponsers.forEach((item, index) => {
                    if (item.url) {
                        fetch(item.url)
                            .then(async response => {
                                const contentType = response.headers.get('content-type')
                                const blob = await response.blob()
                                let file = new File([blob], "image." + item.url.split('.').pop(), { contentType })
                                sponsersArray.push(file)
                                if (sponsersArray.length == sponsers.length) {
                                    resolve();
                                }
                            })

                    } else {
                        sponsersArray.push(item.originFileObj)
                        if (sponsersArray.length == sponsers.length) {
                            resolve();
                        }
                    }
                })
            } else {
                resolve();
            }
        })




        Promise.all([Promisi1]).then(item => {
            console.log(sponsersArray)
            callingApiUpload("/users/fitnessModalAbout/" + id, object, sponsersArray)
        })

    }

    const callingApi = (url, object) => {
        interceptor.axiosGet("post", url, token, object).then(res => {
            debugger
            if (res.status == 200) {
                props.onUpdateAbout(false)
                let passingObje = {
                    userRoledId: userRolesInfo.roleId,
                    token: token,
                    tab: 'about'
                }
                dispatcher(actionsApi.getRolesDetails(passingObje))
                dispatcher(actionsApi.getUserTabDetails(passingObje))
            }
            else {
                OpenNotification("", res.message)

            }
            setIsLoader(false)
        }).catch(error => {
            setIsLoader(false)
        })
    }

    const callingApiUpload = (url, object, images) => {

        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);

        var formdata = new FormData();
        formdata.append("request", JSON.stringify(object));
        if (images && images.length > 0) {
            images.forEach(item => {
                console.log(item)
                formdata.append("images", item);
            });
        }
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(domainurl + url, requestOptions)
            .then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status == "ok") {
                    OpenSuccessNotification("", "Update successfully.")
                    props.onUpdateAbout(false)
                    let passingObje = {
                        userRoledId: userRolesInfo.roleId,
                        token: token,
                        tab: 'about'
                    }
                    dispatcher(actionsApi.getRolesDetails(passingObje))
                    dispatcher(actionsApi.getUserTabDetails(passingObje))

                } else {
                    OpenNotification("", res.message)
                    form.setFields([
                        {
                            name: 'profileUrl',
                            errors: [res.message],
                        },
                    ]);
                }
                setIsLoader(false)
            }).catch(err => {
                setIsLoader(false)
            })

        // interceptor.axiosGet("post", url, token, object).then(res => {
        //     if (res.status == 200) {
        //         OpenNotification("", "Update successfully.")
        //         props.onUpdateAbout(false)
        //         let passingObje = {
        //             userRoledId: userRolesInfo.roleId,
        //             token: token,
        //             tab: 'about'
        //         }
        //         dispatcher(actionsApi.getUserTabDetails(passingObje))
        //     }
        //     setIsLoader(false)
        // }).catch(error => {
        //     setIsLoader(false)
        // })
    }

    // const handleClientPreference = (value) => {
    //     console.log(`selected ${value);
    // };

    // const handleAvailabilityInhome = (value) => {
    //     console.log(`selected ${value);
    // };

    // const handleAvailabilityOnline = (value) => {
    //     console.log(`selected ${value);
    // };


    return (
        <div className='fs-my-account fs-about'>
            <Form
                name="myAccountAboutForm"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <div className='fs-about-head'>
                    <Title level={2}>{fitnessCenterType == 'FM' ? 'Key Info' : 'BUSINESS'}</Title>
                    <div className='fs-circle-btn'>
                        <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
                            <Edit3 size={24} />
                        </div>
                    </div>
                </div>
                {
                    fitnessCenterType == "FC" ? <BusinessType /> :
                        fitnessCenterType == "FP" ?
                            <FitnessProfessional /> :
                            fitnessCenterType == "FM" ?
                                <FitnessModal /> : null
                }

                <Button type="primary" disabled={isLoader} htmlType="submit" loading={isLoader} className="fs-mt-30 fs-mr-15 fs-black">Save</Button>
                <Button type="primary" onClick={onUpdate} >Cancel</Button>
            </Form>
        </div>
    );
}

export default EditAbout;