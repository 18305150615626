// Libraries
import React, { useEffect, useState } from 'react';
import { Tabs, Spin } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';

// Component
import {
    Header,
    About,
    Videos,
    Portfolio,
    Gallery,
    Ads,
    Contact,
    Reviews
} from '../my-account/components';

// Styles
import './style.css';
import useToken from '../../hooks/useToken';
import domainurl from '../../constants/domainurl';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import { useShareDispatch, useShareSelector } from '../../shared';
import { useMyAccountContext } from '../../context/MyAccount/MyAccount';
import interceptor from '../../shared/interceptor';
import { Helmet } from 'react-helmet';


// Assets

const { TabPane } = Tabs;

const PublicProfile = (props) => {
    const { profileUrl } = useParams()
    const item = useLocation()
    const { token } = useToken();
    const dispatcher = useShareDispatch();
    const { isLoadingUserRole } = useShareSelector(state => state.UserRoleDetails)
    const { isLoadingUserDetail } = useShareSelector(state => state.userTabDetail)
    const { setFitnessCenterType, setUserRolesInfo, setPublicUser, fitnessCenterType, userRolesInfo } = useMyAccountContext()
    const onChange = (key) => {
        console.log(key);
    };
    console.log(item.state)

    useEffect(() => {
        debugger
        dispatcher(actionsApi.gettingResponse())
        interceptor.axiosGet("Get", "/users/getUserByProfileUrl/" + profileUrl).then(res => {
            if (res.data.status == "ok") {
                setFitnessCenterType(res.data.result.role)
                setPublicUser(res.data.result._id)
                let passingObje = {
                    userRoledId: res.data.result._id,
                    tab: 'about',
                    readOnly: true,
                    clear: true
                }

                dispatcher(actionsApi.getRolesDetails(passingObje))
                dispatcher(actionsApi.getUserTabDetails(passingObje))

            }
        }).catch(error => {
            console.log(error)
        })


    }, [item.pathname])

    if (isLoadingUserRole) {
        return <div className='fs-spin'><Spin /></div>
    }

    return (
        <div className='fs-my-account'>
            <Header />
            <div className='fs-container'>
                <div className='fs-tabs'>
                    <Tabs defaultActiveKey="about" onChange={onChange}>
                        <TabPane tab="about" key="about">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>
                                    <About />
                                </>
                            }

                        </TabPane>
                        <TabPane tab="video" key="video">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>
                                    <Videos />
                                </>
                            }
                        </TabPane>
                        {
                            userRolesInfo
                                && fitnessCenterType != "FM"
                                ?
                                <TabPane tab="portfolio" key="portfolio">
                                    {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                        :
                                        <>
                                            <Portfolio />
                                        </>
                                    }
                                </TabPane> :
                                null
                        }
                        <TabPane tab="gallery" key="gallery">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>
                                    <Gallery />
                                </>
                            }
                        </TabPane>
                        <TabPane tab="ads" key="ads">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>  <Ads />
                                </>
                            }
                        </TabPane>
                        <TabPane tab="contact" key="contact">
                            <Contact />
                        </TabPane>
                        <TabPane tab="reviews" key="reviews">
                            <Reviews />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default PublicProfile;