// Libraries
import React, { useState } from 'react';
import { Image, Row, Col, Typography, Button, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import AuthImg from '../../assets/images/auth-img.jpg';
import Logo from '../../assets/images/logo.png';
import interceptor from '../../shared/interceptor';
import {OpenNotification} from '../../components/Notifications';

const { Title } = Typography;

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const onFinish = (values) => {
        console.log('Success:', values);
        setLoading(true)
        interceptor.axiosGet("Get", "/users/forgotPassword/" + values.email).then(res => {
            console.log("resposne", res)
            if (res.data.status == "ok") {
                OpenNotification("", "password reset email is sent to your register email")
                navigate('/signin')
                setLoading(false)
            }
        }).catch(error => {
            setLoading(false)
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='fs-auth-container'>
            <Row align='middle'>
                <Col xs={24} md={12} className="fs-img-col">
                    <div className='fs-auth-img'>
                        <Image
                            preview={false}
                            src={AuthImg}
                            alt="Auth Image" />
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className='fs-auth-content'>
                        <Link to="/">
                            <div className='fs-auth-logo'>
                                <Image
                                    preview={false}
                                    src={Logo}
                                    alt="Fitstar" />
                            </div>
                        </Link>
                        <div className='fs-auth-card'>
                            <div className='fs-auth-card-header'>
                                <Title level={2} className="fs-auth-card-header-title">Forgot Password</Title>
                            </div>
                            <Form
                                className='fs-auth-form'
                                layout="vertical"
                                name="loginForm"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your email',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" loading={loading} htmlType="submit">Send</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    );
}

export default ForgotPassword;