// Libraries
import React from 'react';
import { Facebook, Twitter, Linkedin,Youtube } from 'react-feather';

// Component
import { About, Header,Collaborators } from '../../components';

// Styles
import './style.css';

// Assets
import WeightIcon from '../../assets/images/weight.svg';
import AmbassadorsImg from '../../assets/images/auth-img.jpg';

const AboutPage = () => {
    return (
        <div className='fs-about-page'>
            <Header icon={WeightIcon} title="About Us" />
            <About />
            
            <Collaborators
                title="our Collaborators"
                image={AmbassadorsImg}
                cardTitle="Cardio & Strenght Classes"
                fb={<Facebook />}
                tw={<Twitter />}
                li={<Linkedin />}
                yo={<Youtube />}
            />
        </div>
    );
}

export default AboutPage;