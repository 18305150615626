// Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { useShareDispatch } from '../../../../shared';

// Component

// Styles
import './style.css';

// Assets

const Tabs = () => {
    const dispatcher = useShareDispatch();


    return (
        <ul className='fs-tab-list'>
            <li>
                <span onClick={() => {
                    dispatcher(actionsApi.setFitnessDirectory('Fitness Professionals'))
                }}>
                    <Link to="/fitness-directory"> Fitness pros</Link>
                </span>
            </li>
            <li>
                <span onClick={() => {
                    dispatcher(actionsApi.setFitnessDirectory('Fitness Centers'))
                }}>
                    <Link to="/fitness-directory">Fitness centers</Link>
                </span>
            </li>
            <li>
                <span onClick={() => {
                    dispatcher(actionsApi.setFitnessDirectory('Fitness Models'))
                }}>
                    <Link to="/fitness-directory">Fitness models</Link>
                </span>
            </li>
        </ul>
    );
}

export default Tabs;