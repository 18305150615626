// Libraries
import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Form, Input, Button } from 'antd';

// Component
import { Header } from '../../components';

// Styles
import './style.css';

// Assets
import WeightIcon from '../../assets/images/weight.svg';
import AboutImg from '../../assets/images/ContactUs.jpg';
import domainurl from '../../constants/domainurl';
import {OpenNotification} from '../../components/Notifications';
const { Title, Text } = Typography;

const ContactPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();

    const onFinish = (values) => {
        console.log('Success:', values);

      
        setIsLoading(true);
        fetch(domainurl + '/system/contactUs', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        })
            .then((response) => response.json())
            .then(res => {
                setIsLoading(false);
                if (res.status == "ok") {
                    OpenNotification("", "Email successfully sent.");
                    form.resetFields()
                }
            })
            .catch((error) => {
                setIsLoading(false);
            });

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='fs-contact-page'>
            <Header icon={WeightIcon} title="Contact Us" />
            <div className='fs-contact fs-section'>
                <div className='fs-container'>
                    <Row gutter={25} align="middle">
                        <Col xs={24} sm={24} md={12}>
                            <div className='fs-about-img'>
                                <Image preview={false} src={AboutImg} alt="" />
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <div className='fs-contact-content'>
                                <Title level={1}>Contact Form</Title>
                                <Text>Let's get this conversation started. Tell us a bit about yourself, and<span className='fs-span'><br /></span>
                                    we'll get in touch as soon as we can.</Text>
                                <Form
                                    name="contactForm"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className='fs-contactus-form'
                                    form={form}
                                >
                                    <Form.Item
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your name',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Your Name' />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Your Email' />
                                    </Form.Item>
                                    <Form.Item
                                        name="subject"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input subject',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Subject' />
                                    </Form.Item>
                                    <Form.Item
                                        name="message"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input message',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea placeholder='Message' />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" loading={isLoading}>Send</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;