// Libraries
import React, { useEffect, useState } from 'react';
import { Typography, Image, Row, Col, Modal, Popconfirm, Button, } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Plus, Edit3, Trash2, X } from 'react-feather';
import moment from 'moment';

// Component
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import useToken from '../../../../hooks/useToken';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import interceptor from '../../../../shared/interceptor';
import { OpenNotification } from '../../../../components/Notifications';

// Styles
import './style.css';

// Assets

const { Title } = Typography;

const Ads = (props) => {
    const { profileUrl } = useParams();

    const dispatcher = useShareDispatch();
    const { token } = useToken();
    const { userRolesInfo, publicUser } = useMyAccountContext()
    const { userAds } = useShareSelector(state => state.userTabDetail);
    const [modalVisible, setModalVisible] = useState(false);
    const [model, setModel] = useState(null);

    const handleCancel = () => {
        setModalVisible(false);
    };

    const showModal = (item) => {
        let timeJSon = Object.assign({}, item)
        if (userRolesInfo.roleName != "FM") {
            try {
                timeJSon.time = JSON.parse(item.time)
            }
            catch {
                timeJSon.time = [
                    {
                        day: 'Monday',
                        value: item.time,
                    },
                    {
                        day: 'Tuesday',
                        value: item.time,
                    },
                    {
                        day: 'Wednesday',
                        value: item.time,
                    },
                    {
                        day: 'Thursday',
                        value: item.time,
                    },
                    {
                        day: 'Friday',
                        value: item.time,
                    },
                    {
                        day: 'Saturday',
                        value: item.time,
                    },
                    {
                        day: 'Sunday',
                        value: item.time,
                    },
                ]
            }
        }
        setModel(timeJSon)
        setModalVisible(true);
    };

    useEffect(() => {
        if (!userAds) {
            let passingObje = {
                userRoledId: profileUrl ? publicUser : userRolesInfo.roleId,
                token: token,
                readOnly: profileUrl ? true : false,
                tab: 'ads'
            }
            dispatcher(actionsApi.getUserTabDetails(passingObje))
        }
        console.log(userAds)

    }, [userAds])

    const editValue = (value, id) => {
        props.onEditAds(value, id)
    }

    const deleteAds = (_id) => {
        interceptor.axiosDelete("DELETE", "/users/adDelete/" + _id, {}, token).then(res => {
            console.log("resposne", res)
            OpenNotification("", "Ad Deleted Successfully")
            let passingObje = {
                userRoledId: userRolesInfo.roleId,
                token: token,
                tab: 'ads'
            }
            dispatcher(actionsApi.getUserTabDetails(passingObje))
        }).catch(error => {
            console.log(error)
        })
    }



    return (
        <div className='fs-my-account fs-gallery'>
            <div className='fs-about-head'>
                <Title level={2}>Ads</Title>
                {!profileUrl && <div className='fs-circle-btn'>
                    <div to="#" onClick={() =>
                        editValue({ _id: null }, true)} className='fs-round-btn-large ant-btn ant-btn-primary'>
                        <Plus size={24} />
                    </div>
                </div>}
            </div>
            <div className='fs-gallerys'>

                <Row gutter={[25]}>
                    {((userAds || {}).ads || []).map((value, key) => {
                        // console.log('value ->', value);
                        return (
                            <Col xs={24} sm={12} md={12} lg={8} xl={8} onClick={() => showModal(value)}>
                                <div className='fs-gallery-img fs-float-btn'>
                                    {!profileUrl && <div className='fs-circle-btn fs-absolute-btn'>
                                        <p onClick={() =>
                                            editValue(value, true)} className='fs-round-btn-small ant-btn ant-btn-primary fs-mr-15'>
                                            <Edit3 size={18} />
                                        </p>
                                        <Popconfirm
                                            title="Are you sure you want to delete.?"
                                            onConfirm={() => deleteAds(value._id)}

                                        >
                                            <p className='fs-round-btn-small ant-btn ant-btn-primary'>
                                                <Trash2 size={18} />
                                            </p>
                                        </Popconfirm>
                                    </div>}
                                    <Image width="100%" src={value.path} preview={false} />
                                    <div className='fs-ad-footer'>
                                        <Title level={4}>{value.title}</Title>
                                        {value && value.price && <div className='fs-ad-price'>${value.price}</div>}
                                    </div>
                                    <div className='fs-ad-values fs-ad-two'>
                                        {/* <span><strong>Posted on:</strong> {moment(value.time).format('LLL')}</span> */}
                                        {value.modelingInterests.length > 0 && <span><strong>Modeling Interest:</strong> {value.modelingInterests}</span>}
                                        {value.intensityLevel && <span><strong>Intensity Level:</strong> {value.intensityLevel}</span>}
                                    </div>
                                </div>
                            </Col>
                        )
                    })
                    }
                </Row>

                <Modal
                    title={model ? model.title : ''}
                    open={modalVisible}
                    width="600px"
                    onCancel={handleCancel}
                    centered
                    closeIcon={<X size={22} />}
                >

                    <Row gutter={20}>

                        {model &&

                            <Col xs={24} className="modelAddScroll" >
                                <div className='fs-gallery-img fs-float-btn'>
                                    <Image width="100%" src={model.path} />
                                    <div className='fs-ad-footer'>
                                        <Title level={4}>{model.title}</Title>
                                        {model && model.price && <div className='fs-ad-price'>${model.price}</div>}
                                    </div>
                                    <div className='fs-ad-values fs-ad-two'>
                                        {/* {model.time && <span><strong>Posted on:</strong> {moment(model.time).format('LLL')}</span>} */}
                                        {model.modelingInterests.length > 0 && <span><strong>Modeling Interest:</strong> {model.modelingInterests}</span>}
                                        {model.intensityLevel && <span><strong>Intensity Level:</strong> {model.intensityLevel}</span>}
                                    </div>
                                    {model.activities.length > 0 && <div className='fs-ad-values'>
                                        <span>Activity:</span>
                                        <ul>
                                            {model.activities.map((activity, index) => (
                                                <li key={index}>{activity}</li>
                                            ))}
                                        </ul>
                                    </div>}
                                    {/* {model.days.length > 0 && <div className='fs-ad-values'>
                                        <span>Days:</span>
                                        <ul>
                                            {model.days.map((day, index) => (
                                                <li key={index}>{day}</li>
                                            ))}
                                        </ul>
                                    </div>} */}
                                    {model.location && <div className='fs-ad-values'>
                                        <span>Location:</span>
                                        <ul>
                                            <li>{model.location}</li>
                                        </ul>
                                    </div>}

                                    {model.compensation && <div className='fs-ad-values'>
                                        <span>Compensation:</span>
                                        <ul>
                                            <li>{model.compensation}</li>
                                        </ul>
                                    </div>}

                                    {model.spotsAvailability && <div className='fs-ad-values'>
                                        <span>Spots Availability:</span>
                                        <ul>
                                            <li>{model.spotsAvailability}</li>
                                        </ul>
                                    </div>}

                                    <div className='fs-ad-values'>
                                        <span>Description:</span>
                                        <ul>
                                            <li>{model.description}</li>
                                        </ul>
                                    </div>


                                    {model.time &&
                                        <>
                                            <div className='fs-ad-values'>
                                                <ul>
                                                    <h2>Session Times:</h2>
                                                </ul>
                                            </div>
                                            <div className='fs-ad-values' style={{ "marginBottom": "10px" }}>
                                                <ul>
                                                    <table>
                                                        {
                                                            model && model.time && model.time.length > 0 && model.time.map(item => {
                                                                return (
                                                                    item.value && < tr >
                                                                        <th className='fs-about-title'>{item.day}</th>
                                                                        <td>{item.value ? (moment(item.value[0]).format('h:mm:ss a') + " - " + moment(item.value[1]).format('h:mm:ss a'))
                                                                            : 'No Availability'}
                                                                        </td>
                                                                    </tr>

                                                                )
                                                            })
                                                        }
                                                    </table>
                                                </ul>
                                            </div>
                                        </>}

                                </div>
                            </Col>
                        }


                    </Row>
                    <Button type="primary" onClick={handleCancel}>Close</Button>
                </Modal>

            </div>
        </div >
    );
}

export default Ads;