// Libraries
import React, { useEffect, useState } from 'react';
import { Tabs, Spin } from 'antd';
import { Link } from 'react-router-dom';

// Component
import {
    Header,
    About,
    AboutModel,
    AboutCenter,
    EditAbout,
    EditAboutModel,
    EditAboutCenter,
    Videos,
    AddVideo,
    EditVideo,
    Portfolio,
    AddPortfolio,
    EditPortfolio,
    Gallery,
    AddGallery,
    Ads,
    AddAds,
    EditAds,
    Contact
} from './components';

// Styles
import './style.css';
import useToken from '../../hooks/useToken';
import domainurl from '../../constants/domainurl';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';
import { useShareDispatch, useShareSelector } from '../../shared';
import { useMyAccountContext } from '../../context/MyAccount/MyAccount';
import Reviews from './components/Reviews';

// Assets

const { TabPane } = Tabs;

const MyAccount = () => {
    const { token, userLoginObject } = useToken();
    const [editAbout, setEditAbout] = useState(false);
    const [editVideo, setEditVideo] = useState(false);
    const [videoNodel, setvideoModel] = useState(false);
    const [editGallery, setEditGallery] = useState(false);
    const [galleryModel, setGalleryModel] = useState(false);

    const [editportfolio, setEditportfolio] = useState(false);
    const [portfolioModel, setPortfolioModel] = useState(false);
    const [editAds, setEditAds] = useState(false);
    const [adsModel, setAdsModel] = useState(false);
    const dispatcher = useShareDispatch();
    const { isLoadingUserRole } = useShareSelector(state => state.UserRoleDetails)
    const { isLoadingUserDetail } = useShareSelector(state => state.userTabDetail)
    const { setFitnessCenterType, setUserRolesInfo, userRolesInfo } = useMyAccountContext()
    const onChange = (key) => {
        console.log(key);
    };
    const onEditAbout = (item) => {
        setEditAbout(item)
    }
    const onUpdateAbout = (item) => {
        setEditAbout(false)
    }

    const onEditVideo = (item, id) => {
        setvideoModel(item)
        setEditVideo(id)
    }

    const onUpdateVideo = (item) => {
        setEditVideo(false)
    }

    const onEditGallery = (item, id) => {
        setGalleryModel(item)
        setEditGallery(id)
    }

    const onUpdateGallery = (item) => {
        setEditGallery(false)
    }

    const onEditAds = (item, id) => {
        setAdsModel(item)
        setEditAds(id)
    }

    const onUpdateAds = (item) => {
        setEditAds(false)
    }

    const onEditPortfolio = (item, id) => {
        setPortfolioModel(item)
        setEditportfolio(id)
    }

    const onUpdatePortfolio = (item) => {
        setEditportfolio(false)
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        console.log("userLoginObject", userLoginObject)
        let defaultReole = userLoginObject.data.defaultRole;
        const filteredResult = userLoginObject && userLoginObject.data.userRolesInfo && userLoginObject.data.userRolesInfo.find((e) => e.role == defaultReole);
        setFitnessCenterType(defaultReole)
        let passingObje = null
        if (filteredResult) {
            setUserRolesInfo({
                roleId: filteredResult._id ? filteredResult._id : "",
                roleName: defaultReole
            })
            passingObje = {
                userRoledId: filteredResult._id ? filteredResult._id : "",
                token: token,
                tab: 'about',
                readOnly: false,
            }
        }
        else {
            setUserRolesInfo({
                roleId: userLoginObject && userLoginObject.data.userRolesInfo ? userLoginObject.data.userRolesInfo[0]._id : "",
                roleName: userLoginObject.data.userRolesInfo[0].role
            })
            passingObje = {
                userRoledId: userLoginObject.data.userRolesInfo[0]._id,
                token: token,
                tab: 'about',
                readOnly: false,
            }
        }

        console.log(filteredResult)
        dispatcher(actionsApi.getRolesDetails(passingObje))
        dispatcher(actionsApi.getUserTabDetails(passingObje))

    }, [])

    if (isLoadingUserRole) {
        return <div className='fs-spin'><Spin /></div>
    }

    return (
        <div className='fs-my-account'>
            <Header />
            <div className='fs-container'>
                <div className='fs-tabs'>
                    <Tabs defaultActiveKey="about" onChange={onChange}>
                        <TabPane tab="about" key="about">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>
                                    {!editAbout && <About onEditAbout={onEditAbout} />}
                                    {editAbout && <EditAbout onUpdateAbout={onUpdateAbout} />}
                                </>
                            }
                        </TabPane>
                        <TabPane tab="video" key="video">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>
                                    {!editVideo && <Videos onEditVideo={onEditVideo} model={videoNodel} />}
                                    {editVideo && <EditVideo onUpdateVideo={onUpdateVideo} model={videoNodel} />}
                                </>
                            }
                        </TabPane>
                        {
                            userRolesInfo && userRolesInfo.roleName != "FM"
                                ?
                                <TabPane tab="portfolio" key="portfolio">
                                    {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                        :
                                        <>
                                            {!editportfolio && <Portfolio onEditPortfolio={onEditPortfolio} model={portfolioModel} />}
                                            {editportfolio && <AddPortfolio onUpdatePortfolio={onUpdatePortfolio} model={portfolioModel} />}
                                        </>
                                    }

                                    {/* <AddPortfolio /> */}
                                    {/* <EditPortfolio /> */}
                                </TabPane> :
                                null
                        }
                        <TabPane tab="gallery" key="gallery">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>
                                    {!editGallery && <Gallery onEditGallery={onEditGallery} model={galleryModel} />}
                                    {editGallery && <AddGallery onUpdateGallery={onUpdateGallery} model={galleryModel} />}
                                </>
                            }
                        </TabPane>
                        <TabPane tab="ads" key="ads">
                            {isLoadingUserDetail ? <div className='fs-spin'><Spin /></div>
                                :
                                <>  {!editAds && <Ads onEditAds={onEditAds} model={adsModel} />}
                                    {editAds && <AddAds onUpdateAds={onUpdateAds} model={adsModel} />}
                                </>
                            }
                        </TabPane>
                        <TabPane tab="contact" key="contact">
                            <Contact />
                        </TabPane>
                        <TabPane tab="reviews" key="reviews">
                            <Reviews />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default MyAccount;