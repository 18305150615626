// Libraries
import React, { useEffect, useState } from 'react';
import { Facebook, Twitter, Linkedin, Instagram } from 'react-feather';

// Component
import { Hero, Tabs } from './components';
import { About, Ambassadors, Sponsors, FitnessProsSlider } from '../../components';

// Styles
import './style.css';

// Assets
import AmbassadorsImg from '../../assets/images/auth-img.jpg';
import BrandImg from '../../assets/images/brand.png';
import useToken from '../../hooks/useToken';
import { useShareDispatch, useShareSelector } from '../../shared';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';

const HomePage = () => {
    const { token } = useToken();
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const { isLoadinghomePage, homePageData } = useShareSelector(state => state.homePageDetail)
    const dispatcher = useShareDispatch();
    const [about, setAbout] = useState(null);
    const [banner, setBanner] = useState(null);

    useEffect(() => {

        if (homePageData) {
            if (homePageData.landingAboutPage) {
                setAbout(homePageData.landingAboutPage)
            }
            console.log(homePageData)
        }
    }, [homePageData])

    return (
        <div className='fs-home-page'>
            <Hero />
            <Tabs banners={banner} />
            <About />
            <Ambassadors
                title="our ambassadors"
                image={AmbassadorsImg}
                cardTitle="Cardio & Strenght Classes"
                fb={<Facebook />}
                tw={<Twitter />}
                Ins={<Instagram />}
            />
            <Sponsors
                title="our sponsors"
                brandImg={BrandImg}
            />
            <FitnessProsSlider
                type="Fitness Professionals"
                title="TOP FITNESS PROS"
                image={AmbassadorsImg}
                cardTitle="jhon doe"
            />
        </div>
    );
}

export default HomePage;