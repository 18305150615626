// Libraries
import React from 'react';
import { Image, Typography } from 'antd';
import { Edit3, Link } from 'react-feather';

// Component

// Styles
import './style.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// Assets

const { Title } = Typography;

const AboutCenter = (props) => {
    const { model } = props
    const navigate = useNavigate();

    return (
        // <div className='fs-my-account fs-about'>
        //     <div className='fs-about-head'>
        //         <Title level={2}>Business</Title>
        //         <div className='fs-circle-btn'>
        //             <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
        //                 <Edit3 size={24} />
        //             </div>
        //         </div>
        //     </div>
        <ul className='fs-about-list'>
            <li>
                <div className='fs-about-title'>Name</div>
                <div className='fs-about-end'>{model.businessName}</div>
            </li>
            <li>
                <div className='fs-about-title'>Profile Url</div>
                <div className='fs-about-end'>www.fitstar.pro/{model.profileurl}</div>
            </li>
            <li>
                <div className='fs-about-title'>Open Since</div>
                <div className='fs-about-end'>{model.OpenSince}</div>
            </li>
            <li>
                <div className='fs-about-title'>Fitness Center Type</div>
                <div className='fs-about-end'>{model.fitnessCenterType}</div>
            </li>
            <li>
                <div className='fs-about-title'>Languages</div>
                <div className='fs-about-end'>{model.language}</div>
            </li>
            <li>
                <div className='fs-about-title'>Specialties</div>
                <div className='fs-about-end'>{model.sepecialties}</div>
            </li>
            <li>
                <div className='fs-about-title'>Hours Of Operation</div>
                <div className='fs-about-end'>
                    <div style={{ display: "flex" }}>
                        <table>
                            {
                                model && model.timeRange && model.timeRange.length > 0 && model.timeRange.map(item => {
                                    return (
                                        item.value && <tr>
                                            <th className='fs-about-title'>{item.day}</th>
                                            <td>{item.value ? (moment(item.value[0]).format('h:mm:ss a') + " - " + moment(item.value[1]).format('h:mm:ss a'))
                                                : 'Close'}
                                            </td>
                                        </tr>

                                    )
                                })
                            }
                        </table>

                    </div>

                </div>
            </li>
            <li>
                <div className='fs-about-title'>Membership Plans</div>
                <div className='fs-about-end'>{model.membershipplans}</div>
            </li>
            <li>
                <div className='fs-about-title'>Accomplishments</div>
                <div className='fs-about-end'>{model.accomplishments}</div>
            </li>
            <li>
                <div className='fs-about-title'>Our Fitness Pros</div>
                <div className='fs-about-end'>
                    <div className='fs-helper-block fs-helper-zero-padd'>
                        <div className='fs-sponsor-image'>
                            {model && model.fitnessPros.map((item) => (
                                <>
                                    <Image
                                        onClick={() => {
                                            navigate(`/${item.profileUrl}`)
                                        }}
                                        src={item.image}
                                    />
                                </>

                            ))}
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div className='fs-about-title'>Our Story/About Us</div>
                <div className='fs-about-end'>{model.trainingNote}</div>
            </li>
        </ul>
        // </div>
    );
}

export default AboutCenter;