// Libraries
import React from 'react';
import { Image, Typography } from 'antd';
import { Edit3 } from 'react-feather';

// Component

// Styles
import './style.css';

// Assets

const { Title } = Typography;

const AboutModel = (props) => {
    const { model } = props
    console.log(model)
    return (
        // <div className='fs-my-account fs-about'>
        //     <div className='fs-about-head'>
        //         <Title level={2}>Key Info</Title>
        //         <div className='fs-circle-btn'>
        //             <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary'>
        //                 <Edit3 size={24} />
        //             </div>
        //         </div>
        //     </div>
        <ul className='fs-about-list'>
            <li>
                <div className='fs-about-title'>Name</div>
                <div className='fs-about-end'>{model.name}</div>
            </li>
            <li>
                <div className='fs-about-title'>Profile Url</div>
                <div className='fs-about-end'>www.fitstar.pro/{model.profileUrl}</div>
            </li>
            <li>
                <div className='fs-about-title'>Gender</div>
                <div className='fs-about-end'>{model.gender == 1 ? 'Male' : model.gender == 2 ? 'Female' : 'Other'}</div>
            </li>
            <li>
                <div className='fs-about-title'>Age</div>
                <div className='fs-about-end'>{model.age}</div>
            </li>
            <li>
                <div className='fs-about-title'>Height</div>
                <div className='fs-about-end'>{model.height} {model.selectHeight}</div>
            </li>
            <li>
                <div className='fs-about-title'>Weight</div>
                <div className='fs-about-end'>{model.weight} {model.selectWeight}</div>
            </li>
            <li>
                <div className='fs-about-title'>Body Type</div>
                <div className='fs-about-end'>{model.bodyType}</div>
            </li>
            <li>
                <div className='fs-about-title'>Ethnicity</div>
                <div className='fs-about-end'>{model.ethnicity}</div>
            </li>
            <li>
                <div className='fs-about-title'>Skin Tone</div>
                <div className='fs-about-end'>{model.skinTone}</div>
            </li>
            <li>
                <div className='fs-about-title'>Eye Color</div>
                <div className='fs-about-end'>{model.eyeColor}</div>
            </li>
            <li>
                <div className='fs-about-title'>Hair Length</div>
                <div className='fs-about-end'>{model.hairLength}</div>
            </li>
            <li>
                <div className='fs-about-title'>Tattoos</div>
                <div className='fs-about-end'>{model.tattoos}</div>
            </li>
            <li>
                <div className='fs-about-title'>Piercings</div>
                <div className='fs-about-end'>{model.pricing}</div>
            </li>
            <li>
                <div className='fs-about-title'>Experience</div>
                <div className='fs-about-end'>{model.experience}</div>
            </li>
            <li>
                <div className='fs-about-title'>Languages</div>
                <div className='fs-about-end'>{model.languages}</div>
            </li>
            <li>
                <div className='fs-about-title'>Working With Media</div>
                <div className='fs-about-end'>{model.workingWithMedia}</div>
            </li>
            <li>
                <div className='fs-about-title'>Modeling Interests</div>
                <div className='fs-about-end'>{model.ModelingInterests}</div>
            </li>
            <li>
                <div className='fs-about-title'>Compensation</div>
                <div className='fs-about-end'>{model.Compensation}</div>
            </li>
            <li>
                <div className='fs-about-title'>Notes About Compensation</div>
                <div className='fs-about-end'>{model.notesAboutComensation}</div>
            </li>
            <li>
                <div className='fs-about-title'>Activities</div>
                <div className='fs-about-end'>{model.activities}</div>
            </li>
            <li>
                <div className='fs-about-title'>My Story/About Me</div>
                <div className='fs-about-end'>{model.StoryAboutme}</div>
            </li>

            <li className='fs-align-items-top'>
                                <div className='fs-about-title'>Sponsor Images</div>
                                <div className='fs-about-end'>
                                    <div className='fs-helper-block fs-helper-zero-padd'>
                                        <div className='fs-sponsor-image'>
                                            <Image.PreviewGroup>


                                                {model && model.sponsors && model.sponsors.map((item) => (
                                                    <Image
                                                        src={item.path}
                                                    />
                                                ))}

                                            </Image.PreviewGroup>
                                        </div>
                                    </div>
                                </div>
                            </li>
        </ul>
        // </div>
    );
}

export default AboutModel;