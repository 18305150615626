// Libraries
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import useToken from '../../hooks/useToken';
import interceptor from '../../shared/interceptor';
import WeightIcon from '../../assets/images/weight.svg';


// Component

// Styles
import './style.css';
import { Header } from '../../components';

// Assets


const TermsAndCondition = () => {
    const { token } = useToken()
    const [model, setModel] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        interceptor.axiosGet("GET", "/system/getTermsAndCondition", token).then(res => {
            console.log("resposne", res.data.result)
            if (res.data.status == "ok") {
                if (res.data.result) {
                    setModel(JSON.parse(res.data.result.termsAndCondition))
                }
                setLoading(false)
            }
        }).catch(error => {
            setLoading(false)

        })

    }, [])


    return (
        <div className='fs-about fs-section'>
            <Header icon={WeightIcon} title="Terms & Conditions" />
            <div className='fs-container'>

                {
                    loading ?
                        <div className='fs-spin'><Spin /></div>
                        :
                        <div dangerouslySetInnerHTML={{ __html: model }}></div>

                }
            </div>
        </div>
    );
}

export default TermsAndCondition;