// Libraries
import React from 'react';
import { Typography, Image } from 'antd';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

// Component

// Styles
import './style.css';

// Assets

const { Title } = Typography;

const BlogPost = (props) => {
    const { item } = props
    return (
        <div className='fs-blog-post'>
            <div className='fs-blog-post-img'>
                <a href={`https://fitstar.blog/blog-detail/${item._id}`}>
                    <Image preview={false} src={item.pathFeaturedImage} alt={item.title} />
                    {item.category && <span class="ant-tag">{item.category}</span>}
                </a>
            </div>
            <Title level={2}>
                <Link to={`https://fitstar.blog/blog-detail/${item._id}`} >{item.title.substring(0, 200) + '...'}</Link>
            </Title>
            <p>{item.shortDescription}</p>
            <ul className='fs-blog-list'>
                <li>by {item.authorName}</li>
                <li className='fs-list-dot'></li>
                <li>{moment(item.createdAt).format("DD MMM YYYY")}</li>
            </ul>
        </div>
    )
}

export default BlogPost;