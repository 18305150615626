// Libraries
import React, { useEffect, useState } from 'react';
import { Typography, Upload, Button, Row, Col, Form, Rate, Input, Select, TimePicker, InputNumber, Spin } from 'antd';

// Component

// Styles
import './style.css';
import useToken from '../../../../hooks/useToken';
import { OpenNotification, OpenSuccessNotification } from '../../../../components/Notifications';
import interceptor from '../../../../shared/interceptor';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import { Edit3, Mail } from 'react-feather';
import { useParams } from 'react-router-dom';
import domainurl from '../../../../constants/domainurl';
import { useLocation } from 'react-router-dom'
import { useShareSelector } from '../../../../shared';
import moment from 'moment';
// Assets

const { Option } = Select;
const { Title } = Typography;
const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

const Reviews = () => {
    const { profileUrl } = useParams();
    const { token, userLoginObject } = useToken();
    const [address, setAddress] = useState([]);
    const { userObjectData } = useShareSelector(state => state.UserRoleDetails)
    console.log(userObjectData)

    const [isSubmitting, setIsSubmitting] = useState(false)
    const location = useLocation();

    useEffect(() => {

    }, [])



    return (
        <div className='fs-my-account fs-gallery'>
            {userObjectData && userObjectData.review && userObjectData.review.length > 0 ?
                userObjectData.review.map(item => {
                    return (
                        <Col >
                            <div className='fs-user-rating'>
                                <Rate disabled defaultValue={item.points} />
                            </div>
                            < div className=''>
                                <p >{item.message}</p>
                                <p >{ moment(item.updatedAt).format("DD MMM YYYY")}</p>
                            </div>


                        </Col>
                    )
                })

                : <span> No Reviews Available</span>}
        </div >
    );
}

export default Reviews;