// Libraries
import React, { useState, useEffect } from 'react';
import { Typography, Image, Spin } from 'antd';
import Slider from "react-slick";
import { ArrowLeft, ArrowRight, Facebook, Twitter, Linkedin, } from 'react-feather';
import domainurl from '../../constants/domainurl';
// Component

// Styles
import './style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Assets
import WeightIcon from '../../assets/images/weight.svg';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const FitnessProsSlider = (props) => {
    const { type } = props
    const [list, setlist] = useState([]);
    const [isLoadingDetail, setIsLoadingDetail] = useState(false);
    const [types, setType] = useState(null);

    useEffect(() => {
        if (type) { getTopdata(); }
    }, [type]);


    const getTopdata = () => {
        setIsLoadingDetail(true)
        setlist([])
        let path = '';
        if (type) {
            if (type === "Fitness Professionals") {
                path = '/users/getTopFPUserList';
                setType("pro")
            }
            else if (type === "Fitness Centers") {
                path = '/users/getTopFCUserList';
                setType("center")

            }
            else if (type === "Fitness Models") {
                path = '/users/getTopFMUserList';
                setType("model")

            }
        }
        fetch(domainurl + path, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then(res => {
                if (res.status == "ok") {
                    console.log("toppp data", res.result);

                    setlist(res.result);
                }
                setIsLoadingDetail(false)

            })
            .catch((error) => {
                setIsLoadingDetail(false)

            });
    }


    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: list.length == 1 ? 1 : list.length == 2 ? 2 : list.length == 3 ? 3 : 4,
        slidesToScroll: list.length == 1 ? 1 : list.length == 2 ? 2 : list.length == 3 ? 3 : 4,
        prevArrow: <div className='fs-slick-arrows'><ArrowLeft size={18} /></div>,
        nextArrow: <div className='fs-slick-arrows'><ArrowRight size={18} /></div>,
        responsive: [
            {
                breakpoint: 1191,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };

    const settings1 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className='fs-fitness-pros fs-section fs-slick-arrows'>
            <div className='fs-container'>
                <div className='fs-block-header'>
                    <Image preview={false} src={WeightIcon} alt="" />
                    <Title level={1}>{props.title}</Title>
                </div>
                {isLoadingDetail ? <div className='fs-spin'><Spin /></div>
                    :
                    <>
                        <Slider {...settings} className="fs-slick-slider">

                            {
                                list && list.map((s, i) => {
                                    return (
                                        <div className='fs-fitness-pros-slide' key={i} id={i}>
                                            <Link to={`/${s.profileUrl}`} key={i}>
                                                <Image preview={false} src={s.imagePath ? s.imagePath : props.image} alt="" />
                                                <Title level={4}>{s.name}</Title>
                                            </Link>
                                        </div>
                                    )
                                }
                                )
                            }

                        </Slider>
                    </>}

            </div>
        </div>
    )
}

export default FitnessProsSlider;