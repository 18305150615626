// Libraries
import React, { useState, useEffect } from 'react';
import { Image, Row, Col, Form, Input, Select, Tag, Typography, Modal, Button, Upload, Rate, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Edit3, Facebook, Twitter, Linkedin, X, Globe, FileText, Video, Calendar } from 'react-feather';

// Component
import { EmailIcon, PhoneIcon, PinIcon, Website, Instagram } from '../../../../assets/icons/Icons';

// Styles
import './style.css';
import {
    EmailShareButton,
    FacebookShareButton,
    LineShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";

// Assets
import MyAccountImg from '../../../../assets/images/Fitnessproprofile-s.jpg';
import useToken from '../../../../hooks/useToken';
import domainurl from '../../../../constants/domainurl';
import { useShareSelector, useShareDispatch } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import { OpenNotification, OpenSuccessNotification } from '../../../../components/Notifications';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const { REACT_APP_App_URL } = process.env

const { Option } = Select;
const { Title, Paragraph } = Typography;

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const Header = () => {
    const { profileUrl, type } = useParams()
    const { userRoleData, userRolesArray, userObjectData } = useShareSelector(state => state.UserRoleDetails)

    const dispatcher = useShareDispatch();
    const { setFitnessCenterType, fitnessCenterType, setUserRolesInfo, userRolesInfo } = useMyAccountContext()

    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [profileImage, setProfileImage] = useState('');
    const [isUserModalVisible, setisUserModalVisible] = useState(false);
    const { userLoginObject, token, getToken, saveLoginUser } = useToken();
    const [isUpdating, setIsupdating] = useState(false);
    const [loader, setLoader] = useState(false);
    const [userInfoModel, setuserInfoModel] = useState(null);

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);

    const [form] = Form.useForm();


    const showImageModal = () => {
        setIsImageModalVisible(true);
    };

    const handleImageCancel = () => {
        setIsImageModalVisible(false);
    };


    const handleUserCancel = () => {
        setisUserModalVisible(false);
    };

    const handleChange = (value) => {
        let passingObje = {
            userRoledId: value.value,
            token: token,
            tab: 'about',
            clear: true
        }
        console.log(value)
        setFitnessCenterType(value.key)
        setUserRolesInfo({
            roleId: value.value,
            roleName: value.key
        })
        dispatcher(actionsApi.getRolesDetails(passingObje))
        dispatcher(actionsApi.getUserTabDetails(passingObje))
    };

    const onFinishUpload = (values) => {
        console.log('Success:', values);
        setIsupdating(true);
        let bodyJson = {
            "username": values.title,
            "email": values.email,
            "country": values.countryId,
            "state": values.stateId,
            "city": values.cityId,
            //"country": countries.find(x => x._id == values.country) ? values.country : countries.find(x => x.name == values.country)._id,
            // "state": states.find(x => x._id == values.state) ? values.state : states.find(x => x.name == values.state)._id,
            // "city": cities.find(x => x._id == values.city) ? values.city : cities.find(x => x.name == values.city)._id,
            "facebook": values.fb,
            "linkedin": values.lenkedin,
            "twitter": values.twitter,
            "instagram": values.instagram,
            "phoneNumber": values.phone,
            "description": values.description,
            "blogLink": values.blogLink,
            "websiteLink": values.websiteLink,
            "bookingLink": values.bookingLink,
            "liveLink": values.liveLink,
            "addressLine1": values.addressLine1,
            "addressLine2": values.addressLine2,
            "addressLine3": values.addressLine3

        }
        console.log('bodyJson:', bodyJson);
        fetch(domainurl + '/users/updateInfo/' + userRolesInfo.roleId, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "Authorization": `Bearer ${token}` },
            body: JSON.stringify(bodyJson)
        })
            .then((response) => response.json())
            .then((res) => {
                console.log(res)
                if (res.status == "ok") {
                    OpenSuccessNotification("", "user info Updated successfully.")
                    setisUserModalVisible(false);
                    // let countryName = countries.find(x => x._id == values.country) ? countries.find(x => x._id == values.country).name : values.country;
                    // let stateName = states.find(x => x._id == values.state) ? states.find(x => x._id == values.state).name : values.state
                    // let cityName = cities.find(x => x._id == values.city) ? cities.find(x => x._id == values.city).name : values.city

                    let passingObje = {
                        userRoledId: userRolesInfo.roleId,
                        token: token,
                        tab: '',
                        clear: false
                    }
                    dispatcher(actionsApi.getRolesDetails(passingObje))

                    // setuserInfoModel({
                    //     title: `${values.title ? values.title : ''} `,
                    //     email: `${values.email ? values.email : ''} `,
                    //     country: countryName ? countryName : '',
                    //     state: stateName ? stateName : '',
                    //     city: cityName ? cityName : '',
                    //     fb: `${values.fb ? values.fb : ''} `,
                    //     twitter: `${values.twitter ? values.twitter : ''} `,
                    //     lenkedin: `${values.lenkedin ? values.lenkedin : ''} `,
                    //     instagram: `${values.instagram ? values.instagram : ''} `,
                    //     phone: `${values.phone ? values.phone : ''} `,
                    //     description: `${values.description ? values.description : ''} `,
                    //     reviews: `${values.reviews ? values.reviews : ''} `,
                    //     blogLink: values.blogLink ? values.blogLink : '',
                    //     websiteLink: values.websiteLink ? values.websiteLink : '',
                    //     bookingLink: values.bookingLink ? values.bookingLink : '',
                    //     liveLink: values.liveLink ? values.liveLink : '',

                    // })
                }
                else {
                    OpenNotification("", res.message)

                }
                setIsupdating(false);
            })
            .catch((error) => {
                setIsupdating(false);
            });


    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // useEffect(() => {
    //     getCitySateCountryData(-1, -1);

    // }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        console.log(userRolesInfo)
        setProfileImage((userObjectData && userObjectData.profilePicPath) ? userObjectData.profilePicPath : MyAccountImg)
        if (userObjectData && userObjectData.userRolesInfo) {
            let userRoleFilter = null
            if (userRolesInfo.roleName) {
                debugger
                userRoleFilter = userObjectData.userRolesInfo.find(x => x.role == userRolesInfo.roleName)
                if (userRoleFilter) {
                    setUserRolesInfo({
                        roleId: userRoleFilter._id,
                        roleName: userRoleFilter.role
                    })
                }
            } else {
                userRoleFilter = userObjectData.userRolesInfo.find(x => x.role == userObjectData.defaultRole)

            }
            getCitySateCountryData(-1, -1);
            setuserInfoModel({
                title: userObjectData.username ? userObjectData.username : '',
                email: userObjectData.email ? userObjectData.email : '',
                country: userObjectData.country ? userObjectData.country : '',
                state: userObjectData.state ? userObjectData.state : '',
                city: userObjectData.city ? userObjectData.city : '',
                countryId: userObjectData.countryId ? userObjectData.countryId : '',
                stateId: userObjectData.stateId ? userObjectData.stateId : '',
                cityId: userObjectData.cityId ? userObjectData.cityId : '',
                fb: userObjectData.facebook ? userObjectData.facebook : '',
                twitter: userObjectData.twitter ? userObjectData.twitter : '',
                lenkedin: userObjectData.linkedin ? userObjectData.linkedin : '',
                instagram: userObjectData.instagram ? userObjectData.instagram : '',
                blogLink: userObjectData.blogLink ? userObjectData.blogLink : '',
                websiteLink: userObjectData.websiteLink ? userObjectData.websiteLink : '',
                bookingLink: userObjectData.bookingLink ? userObjectData.bookingLink : '',
                liveLink: userObjectData.liveLink ? userObjectData.liveLink : '',
                phone: userObjectData.phoneNumber ? userObjectData.phoneNumber : '',
                description: userObjectData.description ? userObjectData.description : '',
                rating: userObjectData.rating ? userObjectData.rating : '',
                addressLine1: userRoleFilter && userRoleFilter.addressLine1 ? userRoleFilter.addressLine1 : null,
                addressLine2: userRoleFilter && userRoleFilter.addressLine2 ? userRoleFilter.addressLine2 : null,
                addressLine3: userRoleFilter && userRoleFilter.addressLine3 ? userRoleFilter.addressLine3 : null,
                isMissingAnyThing: userObjectData.isMissingAnyThing,

            })
            if (!profileUrl) {
                form.setFieldsValue({
                    title: userObjectData.username ? userObjectData.username : '',
                    email: userObjectData.email ? userObjectData.email : '',
                    country: userObjectData.country ? userObjectData.country : '',
                    state: userObjectData.state ? userObjectData.state : '',
                    city: userObjectData.city ? userObjectData.city : '',
                    countryId: userObjectData.countryId ? userObjectData.countryId : '',
                    stateId: userObjectData.stateId ? userObjectData.stateId : '',
                    cityId: userObjectData.cityId ? userObjectData.cityId : '',
                    fb: userObjectData.facebook ? userObjectData.facebook : '',
                    twitter: userObjectData.twitter ? userObjectData.twitter : '',
                    lenkedin: userObjectData.linkedin ? userObjectData.linkedin : '',
                    instagram: userObjectData.instagram ? userObjectData.instagram : '',
                    blogLink: userObjectData.blogLink ? userObjectData.blogLink : '',
                    websiteLink: userObjectData.websiteLink ? userObjectData.websiteLink : '',
                    bookingLink: userObjectData.bookingLink ? userObjectData.bookingLink : '',
                    liveLink: userObjectData.liveLink ? userObjectData.liveLink : '',
                    phone: userObjectData.phoneNumber ? userObjectData.phoneNumber : '',
                    description: userObjectData.description ? userObjectData.description : '',
                    rating: userObjectData.rating ? userObjectData.rating : '',
                    addressLine1: userRoleFilter && userRoleFilter.addressLine1 ? userRoleFilter.addressLine1 : null,
                    addressLine2: userRoleFilter && userRoleFilter.addressLine2 ? userRoleFilter.addressLine2 : null,
                    addressLine3: userRoleFilter && userRoleFilter.addressLine3 ? userRoleFilter.addressLine3 : null,
                    isMissingAnyThing: userObjectData.isMissingAnyThing,

                });
                if (userObjectData.country && userObjectData.state) { getCitySateCountryData(userObjectData.countryId, userObjectData.stateId); }
            }

        }
    }, [userObjectData])
    const handleChangeImage = (info) => {
        console.log(info)
        if (info.file.status === 'uploading') {

            return;
        }

        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setProfileImage(url)
            });
        }
    };

    const getCitySateCountryData = (cId, sId) => {
        fetch(domainurl + '/users/getcountriesCitiesAndState/' + cId + '/' + sId, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    const result = res.result;
                    setCountries(result.countries);
                    setCities(result.cities);
                    setStates(result.states);
                    //navigate('/signin');
                }

            })
            .catch((error) => {

            });
    }

    const showUserModal = () => {
        setisUserModalVisible(true);
        // console.log(countries)
        // let country
        // if (countries.length > 0) {
        //     country = countries.find(x => x.name == userObjectData.country)._id
        //     getCitySateCountryData(country, -1);
        // }
        // let state
        // if (states.lenth > 0) {
        //     state = states.find(x => x.name == userObjectData.states)._id
        //     getCitySateCountryData(country, state);
        // }

    };

    const onCountryChange = (event) => {
        console.log('seleceedd cont:', event);
        if (event) {
            let cId = event.toString();
            // setCountryId(cId);
            // setStateId('');

            form.setFieldsValue({
                stateId: '',
            });

            // setCityId('');

            form.setFieldsValue({
                cityId: '',
            });
            getCitySateCountryData(cId, -1);
        }

    };


    const onStateChange = (event) => {
        console.log('seleceedd state:', event);
        // setStateId(event);
        // setCityId('');
        form.setFieldsValue({
            cityId: '',
        });
        let countryId = form.getFieldValue('countryId');
        getCitySateCountryData(countryId, event);
    };

    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet>
                    {/* <title>{'FitStar | ' + userInfoModel.title}</title>
                <meta name="title" content={userInfoModel.title} />
                <meta name="url" content={window.location} />
                <meta name="description" content={window.location} />

                <meta name="image:secure_url" content={profileImage} />
                <meta property="image:height" content="360" />
                <meta property="image:width" content="480"></meta>
                <meta property="al:web:url" content="http://www.youtube.com/watch?v=7zOl6BCI89E&amp;list=RDqjfVfE9pEWM&amp;index=17&amp;feature=applinks"></meta> */}

                    <meta property="og:title" content={window.location} />
                    <meta property="og:url" content={window.location} />
                    <meta property="og:image" key="og:image" content={profileImage} />
                </Helmet>
            </HelmetProvider>

            <div className='fs-my-account-header'>
                <div className='fs-container'>
                    <div className='fs-my-account-header-content'>
                        <Row align='middle'>
                            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                <div className='fs-my-account-header-img'>
                                    <Image
                                        preview={false}
                                        src={profileImage}
                                        alt="My Account Image" />
                                    {!profileUrl && <div className='fs-circle-btn'>
                                        <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary' onClick={showImageModal}>
                                            <Edit3 size={24} />
                                        </div>
                                    </div>
                                    }
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                                <div className='fs-my-account-header-meta'>
                                    <div className='fs-my-account-top'>
                                        {!profileUrl &&
                                            <>
                                                <Select
                                                    onChange={(text, index) => {

                                                        handleChange(index)
                                                    }}
                                                    defaultValue={profileUrl ? profileUrl : (userRolesInfo ? userRolesInfo.roleId : null)}
                                                    // onChange={handleChange}
                                                    disabled={profileUrl}
                                                    placeholder="Switch Role"
                                                >
                                                    {userRolesArray.map((item) => (
                                                        <Select.Option key={item.role} value={item._id}>
                                                            {item.fullRole}
                                                        </Select.Option>
                                                    ))}
                                                    {/* {
                                                userRolesArray.lenght && userRolesArray.map((vlaue, key) => {
                                                    console.log(vlaue)
                                                    return (
                                                        <Option value="jack">{vlaue.role}</Option>
                                                    )
                                                })
                                            } */}
                                                    {/* <Option value="lucy">Lucy</Option>
                                            <Option value="Yiminghe">yiminghe</Option> */}
                                                </Select>
                                                <div className='fs-circle-btn'>
                                                    <div to="#" className='fs-round-btn-large ant-btn ant-btn-primary' onClick={showUserModal}>
                                                        <Edit3 size={24} />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                    <div className='fs-my-account-header-middle'>
                                        <div className='fs-my-account-tag'>
                                            {!profileUrl &&
                                                <Tag color="#FF1A1A">{userRolesInfo.roleName == 'FC' ? 'Center' : userRolesInfo.roleName == 'FM' ? 'Model' : 'Pro'}</Tag>
                                            }
                                            {profileUrl &&
                                                <Tag color="#FF1A1A">{fitnessCenterType == 'FC' ? 'Center' : fitnessCenterType == 'FM' ? 'Model' : 'Pro'}</Tag>
                                            }
                                        </div>

                                        {userInfoModel && <Title level={1}>{userInfoModel.title}</Title>}
                                        {userInfoModel && <Paragraph>{userInfoModel.description}</Paragraph>}
                                        <ul className='fs-my-account-contact-list'>
                                            <li>

                                                {userInfoModel && <>
                                                    <PhoneIcon /><span>{userInfoModel.phone}</span></>}
                                            </li>
                                            <li>
                                                <EmailIcon />
                                                {userInfoModel && <span>{userInfoModel.email}</span>}
                                            </li>
                                            {userInfoModel && <li>
                                                <PinIcon />
                                                <span>{userInfoModel.country ? userInfoModel.country + ", " : ''}  {userInfoModel.state ? userInfoModel.state + ", " : ''} {userInfoModel.city ? userInfoModel.city : ''}</span>
                                            </li>}
                                            {userInfoModel && userInfoModel.addressLine1 && <li>
                                                <PinIcon />
                                                <span>{userInfoModel.addressLine1}</span>
                                            </li>}
                                            {userInfoModel && userInfoModel.addressLine2 && <li>
                                                <PinIcon />
                                                <span>{userInfoModel.addressLine2}</span>
                                            </li>}
                                            {userInfoModel && userInfoModel.addressLine3 && <li>
                                                <PinIcon />
                                                <span>{userInfoModel.addressLine3}</span>
                                            </li>}




                                        </ul>
                                        <div className='fs-user-rating'>
                                            {userInfoModel && <Rate disabled defaultValue={userInfoModel.rating} />}
                                            {userInfoModel && <span className="ant-rate-text"> {userInfoModel.rating ? userInfoModel.rating : 0}</span>}
                                        </div>
                                        <ul className="fs-my-account-social">
                                            {userInfoModel && userInfoModel.fb && <li>

                                                <a href={userInfoModel.fb} target="_blank" style={{ backgroundColor: '#4267B2' }}>
                                                    <Facebook size={16} />
                                                </a>
                                            </li>
                                            }
                                            {userInfoModel && userInfoModel.twitter && <li>
                                                <a href={userInfoModel.twitter} target="_blank" style={{ backgroundColor: '#00acee' }}>
                                                    <Twitter size={16} />
                                                </a>
                                            </li>}
                                            {userInfoModel && userInfoModel.lenkedin && <li>
                                                <a href={userInfoModel.lenkedin} target="_blank" style={{ backgroundColor: '#0072b1' }}>
                                                    <Linkedin size={16} />
                                                </a>
                                            </li>}
                                            {userInfoModel && userInfoModel.instagram && <li>
                                                <a href={userInfoModel.instagram} target="_blank" style={{ backgroundImage: "linear-gradient(red, yellow)" }}>
                                                    <Instagram size={16} />
                                                </a>
                                            </li>}
                                        </ul>


                                        <ul className="fs-my-account-social">
                                            {userInfoModel && userInfoModel.blogLink != "" && < li >
                                                <a href={userInfoModel.blogLink} title="Blog Link" target="_blank" style={{ backgroundColor: '#f79c18' }}>
                                                    <FileText size={16} />
                                                </a>
                                            </li>}
                                            {userInfoModel && userInfoModel.bookingLink != "" && <li>
                                                <a href={userInfoModel.bookingLink} title="Booking Site Link" target="_blank" style={{ backgroundColor: '#e83f34' }}>
                                                    <Calendar size={16} />
                                                </a>
                                            </li>}
                                            {userInfoModel && userInfoModel.websiteLink != "" && <li>
                                                <a href={userInfoModel.websiteLink} title="Website Link" target="_blank" style={{ backgroundColor: 'grey' }}>
                                                    <Globe size={16} />
                                                </a>

                                            </li>}
                                            {userInfoModel && userInfoModel.liveLink != "" && <li>
                                                <a href={userInfoModel.liveLink} title="Live Url Link" target="_blank" style={{ backgroundColor: 'rgb(255, 26, 26)' }}>
                                                    <Video size={16} />
                                                </a>

                                            </li>}

                                        </ul>

                                        {userInfoModel && userInfoModel.isMissingAnyThing &&
                                            < div className=''>
                                                <p className='fs-mandatory'>  Your mandatory profile is not complete. Your profile will not be made public until you complete your mandatory profile.</p>
                                            </div>
                                        }
                                        {/* < div className=''>
                                            <p className='fs-mandatory'>
                                                <FacebookShareButton
                                                    url={"http://139.59.242.166:3000/"}
                                                    quote={"FitStart"}
                                                    hashtag={"#hashtag"}
                                                    description="http://139.59.242.166:3000/static/media/logo.2f64850a.png"
                                                    className="Demo__some-network__share-button"
                                                    image={
                                                        "https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80"
                                                      }
                                                >
                                                    <FacebookIcon    size={32} round /> Facebookでshare
                                                </FacebookShareButton>
                                                
                                            </p>
                                        </div> */}



                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {/* Edit Image Modal */}
            <Modal
                title="Upload Profile Image"
                visible={isImageModalVisible}
                onCancel={handleImageCancel}
                centered
                closeIcon={loader ? '' : <X size={22} />}
            >
                {loader ? <div className='fs-upload-spiner'>
                    <Spin ></Spin>
                </div>
                    : ''}
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    maxCount={1}
                    loading={loader}
                    className="upload-profile-image"
                    preview={false}
                    remove={false}
                    onChange={handleChangeImage}
                    beforeUpload={(file) => {
                        setLoader(true)
                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", "Bearer " + token);
                        var formdata = new FormData();
                        formdata.append("image", file);

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(domainurl + "/users/upload/" + userRolesInfo.roleId, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.status == "ok") {
                                    setLoader(false)
                                    setIsImageModalVisible(false)
                                    OpenSuccessNotification("", "Image updated successfully.")

                                    if (userInfoModel.isMissingAnyThing) {
                                        let passingObje = {
                                            userRoledId: userRolesInfo.roleId,
                                            token: token,
                                            tab: '',
                                            clear: false
                                        }
                                        dispatcher(actionsApi.getRolesDetails(passingObje))
                                    }

                                    getBase64(file, (url) => {
                                        setProfileImage(url)
                                        document.getElementById('headerImage').src = url
                                        // debugger
                                        // let obj = getToken()
                                        // obj.data.profilePicPath = url
                                        // saveLoginUser(obj)

                                    });
                                }
                            })
                            .catch(error => console.log('error', error));
                        return false
                    }}
                >
                    <Button disabled={loader}>Upload Image</Button>
                </Upload>
            </Modal>

            {/* User Info Edit Modal */}
            <Modal
                title="Profile Info"
                visible={isUserModalVisible}
                width="600px"
                onCancel={handleUserCancel}
                centered
                closeIcon={<X size={22} />}
            >
                <Form
                    name="editHeaderForm"
                    onFinish={onFinishUpload}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                    className='edit-header-form'
                >
                    <Row gutter={20} className='modelScroll'>
                        <Col xs={24}>
                            <Form.Item
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Name',
                                    },
                                ]}
                            >
                                <Input placeholder='Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12}>
                            <Form.Item
                                name="phone"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input phone number, Please Write NA if you don't want to provide",
                                    },
                                ]}
                            >
                                <Input placeholder='Phone Number' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12}>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input email, Please Write NA if you don't want to provide",
                                    },
                                ]}
                            >
                                <Input placeholder='Email' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <Form.Item
                                name="countryId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select country',
                                    },
                                ]}
                            >
                                <Select onChange={onCountryChange}
                                    showSearch
                                    placeholder="Country"
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countries.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <Form.Item
                                name="stateId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select tate',
                                    },
                                ]}
                            >
                                <Select onChange={onStateChange}
                                    showSearch
                                    placeholder="State"
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {states.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                            <Form.Item
                                name="cityId"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Select city',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="City"
                                    allowClear
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {cities.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24}>
                            <Form.Item name="addressLine1">
                                <Input placeholder="Training location 1" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item name="addressLine2">
                                <Input placeholder="Training location 2" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item name="addressLine3">
                                <Input placeholder="Training location 3" />
                            </Form.Item>
                        </Col> */}
                        <Col xs={24}>
                            <Form.Item
                                name="fb"
                                rules={[
                                    {
                                        message: 'Please input facebook address',
                                    },
                                ]}
                            >
                                <Input placeholder='Facebook Link' />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="twitter"
                                rules={[
                                    {
                                        message: 'Please input twitter address',
                                    },
                                ]}
                            >
                                <Input placeholder='Twitter Link' />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="lenkedin"
                                rules={[
                                    {
                                        message: 'Please input linkedin address',
                                    },
                                ]}
                            >
                                <Input placeholder='Linkedin Link' />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="instagram"
                                rules={[
                                    {
                                        message: 'Please input instagram link',
                                    },
                                ]}
                            >
                                <Input placeholder='Instagram Link' />
                            </Form.Item>
                        </Col>

                        <Col xs={24} >
                            <Form.Item name="blogLink" >
                                <Input placeholder="Blog Site Link" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item name="websiteLink" >
                                <Input placeholder="Website Address" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} >
                            <Form.Item name="bookingLink">
                                <Input placeholder="Booking Address" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item name="liveLink">
                                <Input placeholder="Live Url Link" />
                            </Form.Item>
                        </Col>



                        <Col xs={24}>
                            <Form.Item
                                name="description"
                                rules={[
                                    {
                                        message: 'Favorite Fitness Quote',
                                    },
                                ]}
                            >
                                <Input.TextArea placeholder='Favorite Fitness Quote' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button type="primary" htmlType="submit" loading={isUpdating}>Submit</Button>
                </Form>
            </Modal>

        </React.Fragment >
    );
}

export default Header;