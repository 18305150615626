// Libraries
import React, { useEffect, useState } from 'react';
import { Typography, Upload, Button, Row, Col, Form, Rate, Input, Select, TimePicker, InputNumber, Spin } from 'antd';

// Component

// Styles
import './style.css';
import useToken from '../../../../hooks/useToken';
import { OpenNotification, OpenSuccessNotification } from '../../../../components/Notifications';
import interceptor from '../../../../shared/interceptor';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import { Edit3, Mail } from 'react-feather';
import { useParams } from 'react-router-dom';
import domainurl from '../../../../constants/domainurl';
import { useLocation } from 'react-router-dom'
// Assets

const { Option } = Select;
const { Title } = Typography;
const desc = ['terrible', 'bad', 'normal', 'good', 'wonderful'];

const Contact = () => {
    const { profileUrl } = useParams();
    const { token, userLoginObject } = useToken();
    const [value, setValue] = useState(3);
    const [email, setEmail] = useState(null);
    const [edit, setEdit] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [address, setAddress] = useState([]);
    const { userRolesInfo, publicUser } = useMyAccountContext();

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isFormSubmitting, setisFormSubmitting] = useState(false)
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isRatingLoading, setIsRatingLoading] = useState(false);
    const [emailForm] = Form.useForm();
    const [rateForm] = Form.useForm();
    const onFinish = (values) => {
        console.log('Success:', values);
        onFetchApi(values)

    };

    const onRatingFinish = (values) => {
        console.log('Success:', values);
        console.log('rating Success:', value);

        if (value == 0) {
            OpenNotification("", "Please select rating.");
            return;
        }

        // let url = location.pathname;
        // url = url.split('/');
        values.toUserRolesInfo = publicUser
        values.points = value
        setIsRatingLoading(true);
        fetch(domainurl + '/users/rating', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        })
            .then((response) => response.json())
            .then(res => {
                setIsRatingLoading(false);
                if (res.status == "ok") {
                    rateForm.resetFields()
                    OpenSuccessNotification("topRight", "Rating successfully added.");

                }
            })
            .catch((error) => {
                setIsRatingLoading(false);
            });




    };


    const onRatingFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);

    };



    const onFinishContatDetail = (values) => {


        values.userRolesInfo = publicUser
        setIsLoading(true);
        fetch(domainurl + '/users/userContactEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        })
            .then((response) => response.json())
            .then(res => {
                setIsLoading(false);
                if (res.status == "ok") {
                    emailForm.resetFields()
                    OpenSuccessNotification("", "Email successfully sent.");
                }
            })
            .catch((error) => {
                setIsLoading(false);
            });

    };

    const onFinishFailedContatDetail = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFetchApi = (values) => {
        values.userRolesInfo = userRolesInfo.roleId
        setIsSubmitting(true)
        interceptor.axiosPost("POST", "/users/updateContactEmail", JSON.stringify(values), token).then(res => {
            console.log("resposne", res)
            if (res.data.status == "ok") {
                OpenSuccessNotification("", res.data.result)
                setEmail(values.email)
                setEdit(false)
                setIsSubmitting(false)
            } else {
                OpenNotification("", res.data.result)
                setIsSubmitting(false)
            }

        }).catch(error => {
            setIsSubmitting(false)

            console.log(error)
        })
    }


    const onFinishAddress = (values) => {

        // let url = location.pathname;
        // url = url.split('/');
        // values.userRolesInfo = url[url.length - 1];
        setIsLoading(true);
        interceptor.axiosPost("POST", "/users/addAddressLines/" + userRolesInfo.roleId, values, token).then(res => {
            console.log("resposne", res)
            if (res.data.status == "ok") {
                if (res.data.result) {
                    setAddress(res.data.result)
                    OpenSuccessNotification("", res.data.result);
                    setEditAddress(false)
                }
                else {
                    setEditAddress(false)
                }
            }
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            console.log(error)
        })



    };

    const onFinishFailedAddress = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    useEffect(() => {
        onFetchDetail();
        getAddressLines()

    }, [])

    const onFetchDetail = () => {
        setIsSubmitting(true)
        interceptor.axiosGet("GET", "/users/getUserContactEmail/" + userRolesInfo.roleId, token).then(res => {
            console.log("resposne", res)
            if (res.data.status == "ok") {
                if (res.data.result) {
                    setEmail(res.data.result)
                }
                else {
                    setEdit(true)
                }
            }
            setIsSubmitting(false)
        }).catch(error => {
            setIsSubmitting(false)

            console.log(error)
        })
    }

    const getAddressLines = () => {
        setIsSubmitting(true)
        interceptor.axiosGet("GET", "/users/getAddressLines/" + userRolesInfo.roleId, token).then(res => {
            console.log("resposne", res)
            if (res.data.status == "ok") {
                if (res.data.result) {
                    setAddress(res.data.result)
                }
                else {
                    setEditAddress(true)
                }
            }
            setIsSubmitting(false)
        }).catch(error => {
            setIsSubmitting(false)

            console.log(error)
        })
    }



    return (
        <div className='fs-my-account fs-gallery'>


            {!profileUrl &&
                <>
                    <div className='fs-about-head'>
                        <Title level={2}>Provide Email for Contact Form</Title>
                    </div>
                    {isSubmitting ? <div className='fs-spin'><Spin /></div>
                        :
                        <>

                            {!edit &&
                                <div className=''>
                                    <p >You will receive mails from your customers on this email address. You can change this email by clicking on edit icon</p>

                                    < div className='fs-circle-btn fs-my-contact'>
                                        <Mail size={18} />
                                        <h2 className='ml-2'>{email}</h2>

                                        <p onClick={() =>
                                            setEdit(true)} className='fs-round-btn-small ant-btn ant-btn-primary fs-mr-15'>
                                            <Edit3 size={18} />
                                        </p>
                                    </div>

                                </div>

                            }
                            {/* 
                            {!editAddress &&
                                <div className=''>
                                   
                                    < div className='fs-my-contact'>
                                    <Title level={2}>Address</Title>
                                    <p onClick={() =>
                                        setEditAddress(true)} className='fs-round-btn-small ant-btn ant-btn-primary fs-mr-15'>
                                        <Edit3 size={18} />
                                    </p>
                                    </div>
                                    < div className='fs-circle-btn fs-my-contact'>{address.addressLine1}
                                    </div>
                                    < div className='fs-circle-btn fs-my-contact'>{address.addressLine2}
                                    </div>
                                    < div className='fs-circle-btn fs-my-contact'>{address.addressLine3}
                                    </div>
                                </div>
                            } */}
                        </>

                    }
                    {
                        edit &&
                        <Form
                            name="basic"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <div className='fs-contact fs-mt-30'>
                                <Row gutter={25}>
                                    <Col xs={24} sm={8}>
                                        <Form.Item
                                            name="email"
                                        >
                                            <Input placeholder='Input your email' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='fs-btn-group'>
                                    <Button type="primary" htmlType="submit" className='fs-mr-15 fs-black' loading={isSubmitting}>Save</Button>
                                    <Button type="primary" onClick={() =>
                                        setEdit(false)}>Cancel</Button>
                                </div>
                            </div>
                        </Form>
                    }

                    {/* {
                        editAddress &&
                        <Form
                            name="basic"
                            onFinish={onFinishAddress}
                            onFinishFailed={onFinishFailedAddress}
                            autoComplete="off"
                        >
                            <div className='fs-contact fs-mt-30'>
                                <Row gutter={{
                                    xs: 20,
                                    sm: 20,
                                    md: 24,
                                    lg: 30,
                                }}>
                                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="First Address"
                                            name="addressLine1"
                                        >
                                            <Input placeholder="Input First Address" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="Second Address"
                                            name="addressLine2"
                                        >
                                            <Input placeholder="Input Second Address" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="Third Address"
                                            name="addressLine3"
                                        >
                                            <Input placeholder="Input Third Address" />
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <div className='fs-btn-group'>
                                    <Button type="primary" htmlType="submit" className='fs-mr-15 fs-black' loading={isSubmitting}>Save</Button>
                                    <Button type="primary" onClick={() =>
                                        setEditAddress(false)}>Cancel</Button>
                                </div>
                            </div>
                        </Form>
                    } */}

                </>
            }
            {profileUrl &&

                <>

                    <Row gutter={25}>

                        <Col xs={24} sm={24} md={24} lg={8}>
                            <div className='fs-about-head'>
                                <Title level={2}>Rate Us</Title>
                            </div>
                            <Form

                                name="basic"
                                onFinish={onRatingFinish}
                                onFinishFailed={onRatingFinishFailed}
                                autoComplete="off"
                                form={rateForm}
                            >
                                <div>
                                    <Rate tooltips={desc} onChange={setValue} value={value} />
                                    {value ? <span className="ant-rate-text">{desc[value - 1]}</span> : ''}
                                </div>
                                <Form.Item
                                    name="message"
                                    className='fs-mt-20'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter feedback.',
                                        },
                                    ]}
                                >
                                    <Input.TextArea placeholder='Write your feedback' />
                                </Form.Item>
                                <div className='fs-btn-group fs-mt-20'>
                                    <Button type="primary" htmlType="submit" loading={isRatingLoading}>Submit</Button>
                                </div>
                            </Form>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={16}>
                            <div className='fs-about-head'>
                                <Title level={2}>Contact Us</Title>
                            </div>
                            <Form
                                name="basic"
                                onFinish={onFinishContatDetail}
                                onFinishFailed={onFinishFailedContatDetail}
                                autoComplete="off"
                                className='fs-contact-form'
                                form={emailForm}
                            >
                                <Row gutter={25}>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="name"

                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter name.',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Input your name' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <Form.Item
                                            name="email"

                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter email.',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Input your email' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            name="subject"

                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter subject.',
                                                },
                                            ]}
                                        >
                                            <Input placeholder='Input your subject' />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24}>
                                        <Form.Item
                                            name="message"

                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Enter message.',
                                                },
                                            ]}
                                        >
                                            <Input.TextArea placeholder='Input your message' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className='fs-btn-group'>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>Submit</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </>
            }

        </div >
    );
}

export default Contact;