
import { Button, Divider, notification, Space } from 'antd';
import React from 'react';

//topRight
//topLeft
export const OpenNotification = (placement, desciption) => {
    notification.info({
        message: `Notification ${placement}`,
        description: desciption,
        placement,
    });
};

export const OpenSuccessNotification = (placement, desciption) => {
    notification.success({
        message: `Notification ${placement}`,
        description: desciption,
        placement,
    });
};