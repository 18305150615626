
import { getRolesDetails,gettingResponse,setFitnessDirectory,setUserResponseClear} from "./user/UserRoleDetails";
import { getUserTabDetails,setRoleDetailsClear } from "./user/userDetail";
import { getHomePageDetail } from "./home/homePage";
import { getAllActiveSponsers } from "./home/sponsor";
import { getAllActiveAmbassadors } from "./home/ambassadors";
import { getAllActiveBlogs} from "./home/blogs";

export const actionsApi = {
    getRolesDetails,
    gettingResponse,
    setUserResponseClear,
    setRoleDetailsClear,
    getUserTabDetails,
    getHomePageDetail,
    getAllActiveSponsers,
    getAllActiveAmbassadors,
    setFitnessDirectory,
    getAllActiveBlogs,
    

}