// Libraries
import React, { useState, useEffect } from 'react';

// Component
import { FitnessProsSlider, Header } from '../../components';
import { FitnessPros, FitnessCenters, FitnessModels } from './components';

// Styles
import './style.css';

// Assets
import WeightIcon from '../../assets/images/weight.svg';
import AmbassadorsImg from '../../assets/images/auth-img.jpg';
import TabPane from 'antd/lib/tabs/TabPane';
import { Tabs, Spin } from 'antd';
import { useShareDispatch, useShareSelector } from '../../shared';
import { actionsApi } from '../../redux-toolkit/actions/actionsApi';

const FitnessDirectoryPage = () => {
    const { fitnessDirectory } = useShareSelector(state => state.UserRoleDetails)
    const [selectedTab, setSelectedTab] = useState(fitnessDirectory);
    const [selectedTabSubtitle, setSelectedTabSubtitle] = useState(null);
    const dispatcher = useShareDispatch();

    const onChange = (value) => {
        console.log('tab---' + value)
        dispatcher(actionsApi.setFitnessDirectory(value))


    }

    useEffect(() => {
        setSelectedTabSubtitle('Fitness Professionals')
        if (fitnessDirectory) {
            setSelectedTabSubtitle(fitnessDirectory)
        }
    }, [fitnessDirectory])


    return (
        <div className='fs-fitness-directory'>
            <Header icon={WeightIcon} title="Fitness Directory" subtitle={selectedTabSubtitle} />
            <div className='fd-tab'>
                <Tabs defaultActiveKey={fitnessDirectory} onChange={onChange}>
                    <TabPane tab="Fitness Pros" key="Fitness Professionals">
                        <FitnessPros />
                    </TabPane>
                    <TabPane tab="Fitness Centers" key="Fitness Centers">
                        <FitnessCenters />
                    </TabPane>
                    <TabPane tab="Fitness Models" key="Fitness Models">
                        <FitnessModels />
                    </TabPane>
                </Tabs>
            </div>

            {selectedTabSubtitle && <FitnessProsSlider
                type={selectedTabSubtitle}
                title={'Top ' + selectedTabSubtitle}
                image={AmbassadorsImg}
                cardTitle="jhon doe 1"
            />}
        </div>
    );
}

export default FitnessDirectoryPage;