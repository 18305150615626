// Libraries
import React, { useEffect, useState } from 'react';
import { Row, Col, Image, Typography, Spin } from 'antd';
import { Link, useLocation } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import AboutImg from '../../assets/images/about.png';
import { useShareDispatch, useShareSelector } from '../../shared';

const { Title, Text } = Typography;

const About = () => {

    const location = useLocation();
    const [about, setAbout] = useState(null);
    const { isLoadinghomePage, homePageData } = useShareSelector(state => state.homePageDetail)
    const dispatcher = useShareDispatch();
    console.log(location)
    useEffect(() => {

        if (homePageData) {
            if (homePageData.landingAboutPage) {
                setAbout(homePageData.landingAboutPage)
            }
            console.log(homePageData)
        }
    }, [homePageData])

    return (
        <div className='fs-about fs-section'>
            <div className='fs-container'>
                <Row align="middle">
                    <Col xs={24} sm={24} md={12}>
                        <div className='fs-about-img'>
                            {!about ?
                                <div className='fs-spin'><Spin /></div>
                                : <Image preview={false} src={about ? about.path : AboutImg} alt="" />
                            }
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <div className='fs-about-content'>
                            <Title level={2}>{about ? about.title : ''}</Title>
                            <Title level={1}>{about ? about.heading : ''}</Title>
                            <div className='fs-about-para' dangerouslySetInnerHTML={{ __html: about ? JSON.parse(about.description):''  }}></div>
                            <ul className="fs-about-ul">
                                <li>{about ? about.point1 : ''}</li>
                                <li>{about ? about.point2 : ''}</li>
                                <li>{about ? about.point3 : ''}</li>
                            </ul>
                            {location.pathname !== "/about-us" && <Link to="/about-us" className='ant-btn ant-btn-primary'>find out more</Link>}
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default About;