// Libraries
import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Select, Button, Image, Table } from 'antd';
import { clientPreference, fitnessCenterArray, SpecialtiesArray } from '../../../../data/fitnessCenters';
import domainurl from '../../../../constants/domainurl';

// Component

// Styles
import './style.css';

// Assets
import image from '../../../../assets/images/hero.jpg';
import { Link, useNavigate } from 'react-router-dom';
import useToken from '../../../../hooks/useToken';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';


const { Option } = Select;




const FitnessPros = () => {
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Fitness Pros',
            key: 'imagePath',
            render: (value) => <Image preview={false} src={value && value.imagePath ? value.imagePath : image} alt="" />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <a onClick={() => {
                debugger
                navigate(`/${record.profileUrl}`, { state: record.userRolesInfo })
            }
            }>{text}</a>,

        },
        {
            title: 'Languages',
            dataIndex: 'languages',
            key: 'languages',
        },
        {
            title: 'Specialties',
            dataIndex: 'specialties',
            key: 'specialties',
        },
    ];
    const [id, setId] = useState(null)
    const { userLoginObject } = useToken();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [list, setlist] = useState([]);
    const [filterList, setFilterlist] = useState([]);
    useEffect(() => {

        searchApi(form.getFieldValue());
    }, [""]);

    const onFinish = (values) => {
        let filter = list
        if (values.name) {
            filter = filter.filter(x => x.name.toLowerCase().includes(values.name.toLowerCase()) ||
                x.personalName.toLowerCase().includes(values.name.toLowerCase()));
        }
        if (values.specialities && !!values.specialities.length) {
            filter = filter.filter(x => values.specialities.some(r => x.specialties.includes(r)));
        }
        if (values.language) {
            filter = filter.filter(x => x.languages.join(' ').toLowerCase().includes(values.language.toLowerCase()));
        }
        debugger
        if (values.clientPreference) {
            filter = filter.filter(x => x.availabilityFoCorporateFitness.toLowerCase().includes(values.clientPreference.toLowerCase()));
        }
        if (values.gender) {
            filter = filter.filter(x => x.gender.toLowerCase().includes(values.gender.toLowerCase()));
        }
        if (values.location) {
            filter = filter.filter(x => (x.country.toLowerCase() + " " + x.city.toLowerCase() + ' ' + x.state.toLowerCase()).includes(values.location.toLowerCase()));
        }
        if (values.onLineliveTraining) {
            filter = filter.filter(x => x.onLineliveTraining.toLowerCase().includes(values.onLineliveTraining.toLowerCase()));
        }
        setFilterlist(filter);







        //searchApi(values);
    };


    const searchApi = (values) => {
        // if (userLoginObject && userLoginObject.data) {
        //     values.loginUserId = (userLoginObject || {}).data._id;
        // }
        setIsLoading(true)
        fetch(domainurl + '/users/getFPUserList', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    const userList = []
                    console.log(res.result)
                    if (res.result.length > 0) {
                        debugger
                        for (let a = 0; a < res.result.length; a++) {
                            let user = res.result[a];
                            let userData = {};
                            userData._id = (user || {})._id;
                            const userRoleInfo = user.userRolesInfo[0]
                            const aboutInfo = (userRoleInfo || {}).aboutId
                            userData.imagePath = (userRoleInfo || {}).profilePicPath;
                            userData.country = ((userRoleInfo || {}).country || {}).name
                            userData.state = ((userRoleInfo || {}).state || {}).name
                            userData.city = ((userRoleInfo || {}).city || {}).name
                            userData.name = (userRoleInfo || {}).username || "";
                            userData.userRolesInfo = (userRoleInfo || {})._id;

                            userData.availabilityFoCorporateFitness = ((aboutInfo || {}).business || {}).availabilityFoCorporateFitness || ""
                            userData.onLineliveTraining = ((aboutInfo || {}).business || {}).availabilityForOnlineliveTaining || "";
                            userData.specialties = (((aboutInfo || {}).qualification || {}).specialties || []).join(" , ") || [];
                            userData.languages = (((aboutInfo || {}).qualification || {}).languages || []).join(" , ") || [];
                            userData.gender = ((aboutInfo || {}).personal || {}).gender || "";
                            userData.personalName = ((aboutInfo || {}).personal || {}).name || "";
                            userData.profileUrl = ((aboutInfo || {}).personal || {}).profileUrl || "";
                            userList.push(userData);

                        }

                    }
                    setlist(userList);
                    setFilterlist(userList);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='fs-fitness'>
            <div className='fs-container'>
                <Form
                    name="fitnessProForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    form={form}
                >
                    <Row gutter={25}>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="name"
                            >
                                <Input placeholder='Name' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="specialities"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Specialties"
                                    showArrow
                                    allowClear
                                >
                                    {SpecialtiesArray.map((item) => (

                                        <>
                                            {
                                                item.name !== 'Others' && item.name !== 'All' &&
                                                <Select.Option key={item.key} value={item.name}>
                                                    {item.name}
                                                </Select.Option>
                                            }
                                        </>

                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="location"
                            >
                                <Input placeholder='Location' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="clientPreference"
                            >
                                <Select
                                    // mode="multiple"
                                    placeholder="Corporate Fitness"
                                    showArrow
                                    allowClear
                                // value={selectedRole}

                                // onChange={handleRole}
                                >

                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                    {/* {clientPreference.map((item) => (
                                        <>
                                            {
                                                item.center !== 'Others' &&

                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.center}
                                                </Select.Option>
                                            }
                                        </>

                                    ))} */}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="gender"
                            >
                                <Select
                                    placeholder="Gender"
                                    allowClear
                                >
                                    <Option value="1">Male</Option>
                                    <Option value="2">Female</Option>
                                    <Option value="3">Other</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={8}>
                            <Form.Item
                                name="onLineliveTraining"
                            >
                                <Select
                                    placeholder="Online Live Training "
                                    allowClear
                                >
                                    <Option value="Yes">Yes</Option>
                                    <Option value="No">No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className='fs-right'>
                        {/* <Button type="default" className='fs-mr-15' onClick={() => { form.resetFields(); setFilterlist(list) }}>Reset</Button> */}
                        <Button type="primary" htmlType="submit">Search Fitness Pros</Button>
                    </div>
                </Form>
                <Table
                    columns={columns}
                    dataSource={filterList}
                    loading={isLoading}
                    scroll={{
                        x: 800,
                    }}
                    className="fs-pros" />
            </div>
        </div>
    );
}

export default FitnessPros;