// Libraries
import React, { useEffect, useState } from 'react';
import { Typography, Image, Row, Col, Select, Tag, Modal, Popconfirm } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { Plus, Edit3, Trash2 } from 'react-feather';

// Component

// Styles
import './style.css';
import AddGallery from '../AddGallery';
import { useMyAccountContext } from '../../../../context/MyAccount/MyAccount';
import { useShareDispatch, useShareSelector } from '../../../../shared';
import { actionsApi } from '../../../../redux-toolkit/actions/actionsApi';
import useToken from '../../../../hooks/useToken';
import interceptor from '../../../../shared/interceptor';

// Assets

const { Title } = Typography;

const Gallery = (props) => {
    const { profileUrl } = useParams();
    const dispatcher = useShareDispatch();
    const { token } = useToken();
    const { userRolesInfo,publicUser } = useMyAccountContext()
    const { userGallery } = useShareSelector(state => state.userTabDetail);


    // const [updateImage, setUpdateImage] = useState({
    //     updateImageId: null,
    //     updateImageUrl: null,
    // })

    // const showModal = () => {
    //     setVisible(true);
    // };
    // console.log(userRoleData)
    // const handleOk = () => {

    // };
    // const handleCancel = () => {
    //     console.log('Clicked cancel button');
    //     setVisible(false);
    // };

    const editValue = (value, id) => {
        props.onEditGallery(value, id)
    }

    const deleteVideo = (_id) => {
        interceptor.axiosDelete("DELETE", "/users/galleryDelete/" + _id, {}, token).then(res => {
            console.log("resposne", res)
            let passingObje = {
                userRoledId: userRolesInfo.roleId,
                token: token,
                tab: 'gallery',
                readOnly: false,
            }
            dispatcher(actionsApi.getUserTabDetails(passingObje))
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (!userGallery) {
            let passingObje = {
                userRoledId: profileUrl ? publicUser : userRolesInfo.roleId,
                token: token,
                readOnly: profileUrl ? true : false,
                tab: 'gallery'
            }
            dispatcher(actionsApi.getUserTabDetails(passingObje))
        }
        console.log(userGallery)

    }, [userGallery])

    return (
        <div className='fs-my-account fs-gallery'>
            <div className='fs-about-head'>
                <Title level={2}>Gallery</Title>
                {!profileUrl && <div className='fs-circle-btn'>
                    <div onClick={() =>
                        editValue({ _id: null }, true)} className='fs-round-btn-large ant-btn ant-btn-primary'>
                        <Plus size={24} />
                    </div>
                </div>}
            </div>
            <div className='fs-gallerys'>

                <Image.PreviewGroup>
                    <Row gutter={[25]}>
                        {
                            ((userGallery || {}).galleryItems || []).map((value, key) => {
                                return (
                                    <Col xs={24} sm={12} md={12} lg={8} xl={6}>
                                        <div className='fs-gallery-img fs-float-btn'>
                                            {!profileUrl && <div className='fs-circle-btn fs-absolute-btn'>
                                                {/* <p onClick={() =>
                                                    editValue(value, true)} className='fs-round-btn-small ant-btn ant-btn-primary fs-mr-15'>
                                                    <Edit3 size={18} />
                                                </p> */}

                                                <Popconfirm
                                                    title="Are you sure you want to delete.?"
                                                    onConfirm={() => deleteVideo(value._id)}
                                                    onVisibleChange={() => console.log('visible change')}
                                                >
                                                    <p className='fs-round-btn-small ant-btn ant-btn-primary'>
                                                        <Trash2 size={18} />
                                                    </p>
                                                </Popconfirm>
                                            </div>}
                                            <Image width="100%" src={value.path} />
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Image.PreviewGroup>


            </div>

        </div >
    );
}

export default Gallery;