import * as React from 'react'

const MyAccountContext = React.createContext()

const initalState = {

    fitnessCenterType: null,
    tabType: 'about'

}

const MyAccount = ({ children }) => {
    const [fitnessCenterType, setFitnessType] = React.useState(null);
    const [publicUser, setPublicUser] = React.useState(null);
    const [tabType, setTabType] = React.useState(null);
    const [otherCertification, setOtherCertification] = React.useState(null)
    const [userRolesInfo, setUserRolesInfo] = React.useState({
        roleId: null,
        roleName: null
    })
    const [localStr, setLocalSTr] = React.useState()



    const value = {
        fitnessCenterType: fitnessCenterType,
        publicUser: publicUser,
        tabType: tabType,
        otherCertification: otherCertification,
        setOtherCertification: setOtherCertification,
        setFitnessCenterType: setFitnessType,
        setPublicUser: setPublicUser,
        userRolesInfo: userRolesInfo,
        setUserRolesInfo: (roleObj) => {
            setUserRolesInfo({ ...userRolesInfo, roleId: roleObj.roleId, roleName: roleObj.roleName })
        }
    }
    console.log(otherCertification)
    return <MyAccountContext.Provider value={value}>{children}</MyAccountContext.Provider>
}

const useMyAccountContext = () => {
    const context = React.useContext(MyAccountContext)
    if (context === undefined) {
        throw new Error('useCount must be used within a CountProvider')
    }
    return context
}
export { MyAccount, useMyAccountContext }