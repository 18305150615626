import { configureStore,combineReducers } from "@reduxjs/toolkit";
import UserRoleDetails from "./actions/user/UserRoleDetails";
import userTabDetail from "./actions/user/userDetail";
import homePageDetail from "./actions/home/homePage";
import sponsorData from "./actions/home/sponsor";
import ambassadorsDetail from "./actions/home/ambassadors";
import blogs from "./actions/home/blogs";


const store = configureStore({
	reducer: {
		UserRoleDetails,
		userTabDetail,
		homePageDetail,
		sponsorData,
		ambassadorsDetail,
		blogs,

	},
	

	// middleware:[...getDefaultMiddleware,loggerMidleWare]
	// The thunk middleware was automatically added
	// https://github.com/reduxjs/redux-thunk
});



export default store;

