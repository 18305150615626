// Libraries
import React, { useEffect, useState } from 'react';
import { Image, Spin, Typography } from 'antd';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import HeroImg1 from '../../../../assets/images/after-img.jpg';
import HeroImg2 from '../../../../assets/images/post.jpg';
import HeroImg3 from '../../../../assets/images/hero.jpg';
// Component

// Styles
import './style.css';
import { useShareSelector } from '../../../../shared';

// Assets

const model = [
    {
        path: HeroImg1
    },
    {
        path: HeroImg2
    },
    {
        path: HeroImg3
    }
]
const { Title, Paragraph } = Typography;

const Hero = (props) => {

    const [banner, setBanner] = useState(null);
    const { isLoadinghomePage, homePageData } = useShareSelector(state => state.homePageDetail)
    useEffect(() => {
        if (homePageData) {
            console.log(homePageData)

            if (homePageData.landingBanner) {
                setBanner(homePageData.landingBanner)
            }
        }
    }, [homePageData])

    const { banners } = props
    var settings = {
        dots: true,
        infinite: true,
        arrows: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };
    return (
        <div className='banner'>

            {
                isLoadinghomePage ? <div className='fs-spin'><Spin /></div>
                    :

                    <Slider {...settings}>

                        {(banner || []).map(item => {
                            return (
                                <>
                                    <div className='fs-hero' style={{
                                        backgroundImage: 'url(' + item.path + ')'
                                    }}>
                                        <div className='fs-container' >
                                            < div className='fs-hero-content'>
                                                <Title level={1}>{item.title} <br />
                                                    {item.tag && <span>{item.tag}</span>}
                                                </Title>
                                                <Paragraph className='fs-hero-para'>{item.description}</Paragraph>
                                                <center>
                                                    <Link to="/signup" className='ant-btn ant-btn-primary'>get started</Link>
                                                </center>
                                            </div>
                                        </div >
                                    </div >
                                </>
                            )
                        })
                        }


                    </Slider>
            }
        </div>

    );
}

export default Hero;