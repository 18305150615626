import _Header from './Header';
import _About from './About';
import _AboutModel from './AboutModel';
import _AboutCenter from './AboutCenter';
import _EditAbout from './EditAbout';
import _EditAboutModel from './EditAboutModel';
import _EditAboutCenter from './EditAboutCenter';
import _Videos from './Videos';
import _AddVideo from './AddVideo';
import _EditVideo from './EditVideo';
import _Portfolio from './Portfolio';
import _AddPortfolio from './AddPortfolio';
import _EditPortfolio from './EditPortfolio';
import _Gallery from './Gallery';
import _AddGallery from './AddGallery';
import _Ads from './Ads';
import _AddAds from './AddAds';
import _EditAds from './EditAds';
import _Contact from './Contact';
import _Reviews from './Reviews';

export const Header = _Header;
export const About = _About;
export const AboutModel = _AboutModel;
export const AboutCenter = _AboutCenter;
export const EditAbout = _EditAbout;
export const EditAboutModel = _EditAboutModel;
export const EditAboutCenter = _EditAboutCenter;
export const Videos = _Videos;
export const AddVideo = _AddVideo;
export const EditVideo = _EditVideo;
export const Portfolio = _Portfolio;
export const AddPortfolio = _AddPortfolio;
export const EditPortfolio = _EditPortfolio;
export const Gallery = _Gallery;
export const AddGallery = _AddGallery;
export const Ads = _Ads;
export const AddAds = _AddAds;
export const EditAds = _EditAds;
export const Contact = _Contact;
export const Reviews = _Reviews;