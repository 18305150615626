// Libraries
import React, { useState, useEffect } from 'react';
import { Image, Row, Col, Typography, Button, Form, Input, Select, Checkbox } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

// Component

// Styles
import './style.css';

// Assets
import AuthImg from '../../assets/images/auth-img.jpg';
import Logo from '../../assets/images/logo.png';
import domainurl from '../../constants/domainurl';
import { useForm } from 'rc-field-form';
import useToken from '../../hooks/useToken';
import interceptor from '../../shared/interceptor';
import {OpenNotification} from '../../components/Notifications';

const { Title, Text } = Typography;
const { Option } = Select;

// "FP"="fitness Professional"
// "FC"="Fitness Center"
// "FM"="Fitness Modal"
const ROLE_OPTIONS = [{ "center": 'Fitness Center', "key": "FC" }, { "center": 'Fitness Model', "key": "FM" }, { "center": 'Fitness Professional', "key": "FP" }];

const Signup = () => {

    let navigate = useNavigate();
    const [selectedItems, setSelectedItems] = useState(null);

    const [selectedRole, setSelectedRole] = useState([]);
    const filteredRole = selectedRole.length && ROLE_OPTIONS.filter((o) => !Object.values(o).includes("key"));

    const [isLoading, setIsLoading] = useState(false);

    const [countryId, setCountryId] = useState('');
    const [stateId, setStateId] = useState('');
    const [cityId, setCityId] = useState('');

    const [form] = Form.useForm();
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [message, setMessage] = useState('');
    const { saveLoginUser } = useToken();

    const [referedUserList, setReferedUserList] = useState([]);

    useEffect(() => {
        console.log("Executed only once!");
        getCitySateCountryData(-1, -1);
        getReferredList();
    }, [""]);

    const onFinish = (values) => {
        console.log('Success:', values);
        signUpUser(values);
    };

    const handleRole = (value) => {
        console.log(`selected ${value}`);
        setSelectedRole(value)
    };


    const signUpUser = (values) => {
        setIsLoading(true);

        if (values.userRole.length == 1) {
            values.defaultRole = values.userRole[0];
        }

        let bodyJson = {
            "username": values.username,
            "email": values.email,
            // "userRole": "Fitness Center",
            "userRole": values.userRole,
            "country": values.country,
            "state": values.state,
            "city": values.city,
            "defaultRole": values.defaultRole,
            "password": values.password,
            "preferredBy": values.whoRefered == "other" ? null : values.whoRefered,
            "referedBy": values.referedBy
        }
        console.log(bodyJson)

        fetch(domainurl + '/users/create', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyJson)
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    signInAfeterSignUp(values)
                } else {
                    OpenNotification("", res.message)

                }
                setIsLoading(false);

            })
            .catch((error) => {
                setIsLoading(false);
            });
    }


    const signInAfeterSignUp = (values) => {
        let bodyJson = {
            "email": values.email,
            "password": values.password,
        }
        fetch(domainurl + '/users/authenticate', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyJson)
        })
            .then((response) => response.json())
            .then(res => {
                if (res.status == "ok") {
                    saveLoginUser(res.result);
                    navigate('/myaccount');
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });

    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const getCitySateCountryData = (cId, sId) => {

        fetch(domainurl + '/users/getcountriesCitiesAndState/' + cId + '/' + sId, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    const result = res.result;
                    setCountries(result.countries.sort((a, b) => a.name.localeCompare(b.name)));
                    setCities(result.cities.sort((a, b) => a.name.localeCompare(b.name)));
                    setStates(result.states.sort((a, b) => a.name.localeCompare(b.name)));
                    //navigate('/signin');
                }

            })
            .catch((error) => {

            });
    }



    const onCountryChange = (event) => {
        console.log('seleceedd cont:', event);
        let cId = event.toString();
        setCountryId(cId);
        setStateId('');

        form.setFieldsValue({
            state: '',
        });

        setCityId('');

        form.setFieldsValue({
            city: '',
        });
        getCitySateCountryData(cId, -1);
    };


    const onStateChange = (event) => {
        console.log('seleceedd state:', event);
        setStateId(event);
        setCityId('');
        form.setFieldsValue({
            city: '',
        });
        getCitySateCountryData(countryId, event);
    };



    const getReferredList = () => {

        fetch(domainurl + '/users/whoReferredBy', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
            .then((response) => response.json())
            .then((res) => {
                if (res.status == "ok") {
                    const result = res.result;
                    result.push({ "_id": "other", "email": "Other" })
                    setReferedUserList(result);
                }

            })
            .catch((error) => {

            });
    }

    const checkPassword = (rule, value, callback) => {
        let pass = form.getFieldValue('cPassword')
        let cPass = form.getFieldValue('password')
        if (pass && cPass && pass !== cPass) {
            // callback("The passwords don't match");
            form.setFields([
                {
                    name: 'cPassword',
                    errors: ["The passwords don't match"],
                },
            ]);
        } else {
            form.setFields([
                {
                    name: 'cPassword',
                    errors: [],
                },
            ]);
        }
    };
    const getSelectedRole = () => {

        let defaultRoleList = [];

        ROLE_OPTIONS.forEach(element => {
            if (selectedRole.indexOf(element.key) > -1) {
                defaultRoleList.push(element);
            }

        });

        return defaultRoleList;
    }

    return (
        <div className='fs-auth-container'>
            <Row align='middle'>
                <Col xs={24} md={12} className="fs-img-col">
                    <div className='fs-auth-img'>
                        <Image
                            preview={false}
                            src={AuthImg}
                            alt="Auth Image" />
                    </div>
                </Col>
                <Col xs={24} md={12} className="fs-auth-content-col">
                    <div className='fs-auth-content'>
                        <Link to="/">
                            <div className='fs-auth-logo'>
                                <Image
                                    preview={false}
                                    src={Logo}
                                    alt="Fitstar" />
                            </div>

                        </Link>
                        <div className='fs-auth-card'>
                            <div className='fs-auth-card-header'>
                                <Title level={2} className="fs-auth-card-header-title">Sign Up</Title>
                                <Text>Already have and account?<Link to='/signin'>Sign in</Link></Text>
                            </div>
                            <Form
                                className='fs-auth-form'
                                layout="vertical"
                                // name="loginForm"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                form={form}
                            >
                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your full name',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Full Name" />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Please input valid E-mail!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your E-mail!',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <Row gutter={16}>
                                    <Col xs={24} sm={12} md={24} lg={12}>
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                            ]}
                                        >
                                            <Input.Password placeholder="Password" onBlur={checkPassword} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={24} lg={12}>
                                        <Form.Item
                                            name="cPassword"
                                            className='fs-field-mrgn'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please confirm your password!',

                                                },

                                            ]}
                                        >
                                            <Input.Password placeholder="Confirm Password" onBlur={checkPassword} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    name="whoRefered"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select who refered you to fitstar',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Who refered you to fitstar"
                                        allowClear={true}
                                        value={selectedItems}
                                        onChange={setSelectedItems}
                                    >
                                        {referedUserList.map((s, key) => <Option key={key} value={s._id}>{s.email}</Option>)}
                                    </Select>
                                </Form.Item>

                                {((selectedItems && selectedItems == "other")) && <div className=' fs-fs-myaccount-sec'>
                                    <Form.Item
                                        name="referedBy"
                                        rules={[
                                            {
                                                required: ((selectedItems && selectedItems.includes("Other")) ? true : false),
                                                message: 'Please input Reffered By',
                                            },
                                        ]}
                                    >
                                        <Input placeholder='Please input Reffered by if not in List' />
                                    </Form.Item>
                                </div>}

                                <Form.Item
                                    name="userRole"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select a role',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Select a Role"
                                        showArrow
                                        value={selectedRole}
                                        onChange={handleRole}
                                    >
                                        {ROLE_OPTIONS.map((item) => (
                                            <Select.Option key={item.key} value={item.key}>
                                                {item.center}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {selectedRole.length > 1 ?
                                    <Form.Item
                                        name="defaultRole"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Select Default Role',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select Default Role"
                                        >
                                            {getSelectedRole().map((item) => (
                                                <Select.Option key={item.key} value={item.key}>
                                                    {item.center}
                                                </Select.Option>
                                            ))}
                                            {/* <Option value="jack">Jack</Option>
                                        <Option value="lucy">Lucy</Option>
                                        <Option value="tom">Tom</Option> */}
                                        </Select>
                                    </Form.Item>
                                    : null}

                                <Row gutter={16}>
                                    <Col xs={24} sm={8} md={24} lg={8}>
                                        <Form.Item
                                            name="country"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select country',
                                                },
                                            ]}
                                        >



                                            <Select
                                                showSearch
                                                onChange={onCountryChange}
                                                placeholder="Country"
                                                value={countryId}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }

                                            >
                                                {countries.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={24} lg={8}>
                                        <Form.Item
                                            name="state"
                                            className='fs-field-mrgn'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select state',
                                                },
                                            ]}
                                        >
                                            <Select onChange={onStateChange}
                                                showSearch
                                                placeholder="State"
                                                value={stateId}
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {states.map(s => <Option key={s._id} value={s._id}>{s.name}</Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={24} lg={8}>
                                        <Form.Item
                                            name="city"
                                            className='fs-field-mrgn'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Select city',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="City"
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {cities.map(c => <Option key={c._id} value={c._id}>{c.name}</Option>)}

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    name="agree"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                        },
                                    ]}
                                >
                                    <Checkbox>I agree the supplied information is true and agree to FitStar Pro's Terms and Conditions.</Checkbox>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>sign up</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>

        </div>
    );
}

export default Signup;